import React, { useState, useEffect } from 'react';
import { PostAsync, formatoFecha } from '../helpers/Functions';
import StateContext from '../helpers/ContextState'
import TableResponsive from './TableResponsive';
import Paginator from '../components/Paginator';
import SelectorMultiple from "../components/SelectorMultiple"
import UploadExcel from "../components/UploadExcel"
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
// import Select from '../components/Select';
import Select from 'react-select';
import Input from '../components/InputSimple';
import BotonVolverHome from '../components/BotonVolverHome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from '@mdi/react';
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { mdiMicrosoftExcel } from '@mdi/js';
import Config from '../helpers/Config';


import { Inputs } from '../helpers/Functions';
import { useParams } from "react-router-dom";

import Store from '../helpers/Store'
import { style } from 'd3-selection';
import FilterList from './FilterList';

const HtmlToReactParser = require('html-to-react').Parser;
let htmlToReactParser = new HtmlToReactParser();



let paginacion = []

const onFilterChange =()=>{
  return "jeje"
}


let filter = "";
let hoy = formatoFecha(new Date(), "yy-mm-dd");
let btn_back = false

var previousState = 0
window.addEventListener('popstate', (event) => {
  if (event.state > previousState) {
    btn_back = false
  } else {
    btn_back = true
  }
  previousState = event.state
})

const App = (props) => {
  //console.log(props);
  const paramsUser = useParams();
  let limit, limit2 = parseInt(process.env.REACT_APP_NUM_X_PAGE);
  const context = React.useContext(StateContext);
  const [data, setData] = useState([]);
  const [paginas, setPaginas] = useState([]);
  const [semanas, setSemanas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [llevaPaginacion, setLlevaPaginacion] = useState(true);
  const [totalizacion, setTotalizacion] = useState({});
  const [totalizacion_general, setTotalizacion_general] = useState({});
  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isUsuarios, setIsUsuarios] = useState(false);
  const styleFont = {
    backgroundColor: '#273c7520',
  };
  const styleNone = {
    color: 'black',
  };
  const params_url = useParams();
  // console.log(params_url)
  /*cambio*/
  const [paginaActual, setPaginaActual] = useState({ label: 1, start: 0 });

  const [open, setOpen] = useState(false);
  const [total_rows_empty, setTotal_rows_empty] = useState(true);

  const [parameters, setParameters] = useState(false);

  const [inputs, setInputs] = useState({ fecha_recibido: hoy });
  const [inputs2, setInputs2] = useState({});
  const [inputs3, setInputs3] = useState("");
  const [precio_totalizado_final_string, setprecio_totalizado_final_string] = useState("");
  const [cantidad_totalizado_final_string, setcantidad_totalizado_final_string] = useState("");
  const [peso_totalizado_final_string, setpeso_totalizado_final_string] = useState("");

  const [reset, setReset] = useState(false);


  const onChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    getInit()
  }

  React.useEffect(() => {
    getInit()
    console.log(props.params);
  }, [props.params])

  const getInit = (obj) => {
    // console.log(context);
    setIsUsuarios(window.location.pathname.split('/').includes('Usuarios'));
    setData([])
    let data_ = { ...props }
    if (props.params && typeof props.params === "object") {
      Object.entries(props.params).map((row, key) => {
        return data_[row[0]] = row[1]
      })
      delete (data_.params);
    }

    delete (data_.td);
    data_.fecha = hoy;
    data_.limit = process.env.REACT_APP_NUM_X_PAGE
    data_.start = obj ? obj.paginaActual.start : paginaActual.start
    if (filter !== '') {
      data_.filter = filter
    }
    let parameters_send = { ...data_, ...inputs, filtroMultiple: JSON.stringify(inputs2), ...params_url, active: true }
    let parameters_save = { data: data_, inputs: inputs, inputs2: inputs2, params_url: params_url, active: true }
    // console.log(params_url)

    if (!btn_back || (btn_back && !Store.get(window.location.pathname).active)) {
      //console.log("si llena");
      Store.set(window.location.pathname, parameters_send)
      Store.set(window.location.pathname + "save", parameters_save)
    } else if (btn_back && Store.get(window.location.pathname).active) {
      //console.log("llena del store",Store.get(window.location.pathname));
      parameters_send = Store.get(window.location.pathname)
      parameters_save = Store.get(window.location.pathname + "save")
      btn_back = false
      setInputs(parameters_save.inputs)
      setInputs2(parameters_save.inputs2)
      setReset(true)
      setTimeout(function () {
        setReset(false)
      }, 1000);
    }
    Store.set(window.location.pathname, { ...data_, ...inputs, filtroMultiple: JSON.stringify(inputs2), ...params_url })
    // console.log(context.user);
    PostAsync(props.modelo, props.metodo, parameters_send, context).then((response) => {
      console.log("response",response)
      console.log("props.metodo",props.metodo)
      setprecio_totalizado_final_string(response.precio_totalizado_final_string)
      setcantidad_totalizado_final_string(response.cantidad_totalizado_final_string)
      setpeso_totalizado_final_string(response.peso_totalizado_final_string)

      //return setOpen(true);
      if (response === undefined || response.data === undefined) {
        return false;
      }

      if (response.data && (response.data.total_rows === undefined || response.data.total_rows === '0')) {
        setData([])
        setTotal_rows_empty(false)
        setOpen(true)
      }

      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas)
      }

      if (response.data && response.data.plantas) {
        setPlantas(response.data.plantas)
      }

      if (response.data && response.data.proveedores) {
        // console.log("response.data.proveedores",response.data.proveedores);
        if(parseInt(context.user.tipo_usuario_id) === 3){
          const proveedoresFiltrados = response.data.proveedores.filter((proveedor) => proveedor.label === (proveedor.ma_naturaleza_id == 1)?proveedor.nombres + ' ' + proveedor.apellidos:proveedor.nombres);
          setProveedores(proveedoresFiltrados)
        }else{
          setProveedores(response.data.proveedores)
        }
      }

      if (response.data && response.data.marcas) {
        setMarcas(response.data.marcas)
      }
      if (response.data && response.data.clientes) {
        setClientes(response.data.clientes)
      }


      if (response.data && response.data.productos) {
        setProductos(response.data.productos)
      }

      if (response.totalizacion) {
        setTotalizacion(response.totalizacion)
      }
      
      // if (response.lleva_paginacion) {
        // console.log("response.lleva_paginacion", response.lleva_paginacion)
        setLlevaPaginacion(response.lleva_paginacion)
      // }
 
      if (response.totalizacion_general) {
        setTotalizacion_general(response.totalizacion_general)
      }

      if (response.totalUsuarios) {
        setTotalUsuarios(response.totalUsuarios)
      }


      let trows = (response.data.total_rows !== undefined) ? parseInt(response.data.total_rows) : 0;
      limit = parseInt(process.env.REACT_APP_NUM_X_PAGE)
      paginacion = Math.ceil(trows / process.env.REACT_APP_NUM_X_PAGE)
      setData(response.data.rows)

      if (response.data && response.data.total_rows && response.data.total_rows !== "0") {
        //setTotal_rows_empty(false)
        setTotal_rows_empty(response.data.total_rows)
      }

      /*cambio*/
      let pages = []
      let ultimo = 0
      for (var i = 0; i < paginacion; i++) {
        ultimo = limit2 * (i)
        pages.push({ label: i + 1, start: ultimo })
      }
      setPaginas(pages)

      setOpen(true)
    })
  }



  const handleSetPaginaActual = (data) => {
    setPaginaActual(data)
    let [first] = Object.keys(props.params)
    let value = props.params[first]
    setParameters({ label: "first", value: value })
    getInit({ paginaActual: data })
  }

  // /*cambio*/
  // useEffect(() => {
  //   let [first] =   Object.keys(props.params)
  //   let value   =   props.params[first]
  //   setParameters({label:"first",value:value})
  //   //getInit()
  // },[])
  // //},[])


  // const onChange=(e)=>{
  //   setPaginaActual({label: 1, start: 0})
  //   e.preventDefault()
  //   filter=e.target.value
  //   getInit()
  // }

  const changeEstatus = (row) => {
    // console.log(row.estatus_pago)
    if (row.estatus_pago == 1) {
      context.setModalShow({
        show: true,
        message: <div className="text-center h5">
          {"No puedes eliminar una compra ya paga"}
          <div className="row justify-content-center mt-3">
            <div className="col-3" onClick={() => context.setModalShow({ show: false })}>
              <div className="btn btn-secondary btn-block">Cancelar</div>
            </div>
          </div>
        </div>,
        size: ""
      })

    } else {
      context.setModalShow({
        show: true,
        message: <div className="text-center h5">
          {"¿Deseas eliminar este producto?"}
          <div className="row justify-content-center mt-3">
            <div className="col-3" onClick={() => { submitChangeEstatus(row, props.metodo); context.setModalShow({ show: false }); }}>
              <div className="btn btn-primary btn-block">Eliminar</div>
            </div>
            <div className="col-3" onClick={() => context.setModalShow({ show: false })}>
              <div className="btn btn-secondary btn-block">Cancelar</div>
            </div>
          </div>
        </div>,
        size: ""
      })
    }
  }

  const submitChangeEstatus = (row, metodo) => {

    const user_token = Store.get("user").token;

    let data_ = {
      token: user_token,
      id: row.token
    }


    if (metodo === "ListadoVentas") {
      PostAsync("Inventario", "deleteVenta", data_, {}, { name: "callbackContinue4", funct: callbackContinue4 }).then((resp) => getInit())
    }
    else if (metodo === "ListadoCompras") {
      PostAsync("Inventario", "DeleteAllRegister", data_, {}, { name: "callbackContinue4", funct: callbackContinue4 }).then((resp) => getInit())
    }
    else {
      PostAsync("Inventario", "setEstatus", data_, {}, { name: "callbackContinue4", funct: callbackContinue4 }).then((resp) => getInit())
    }
  }

  const callbackContinue4 = (resp) => {
    getInit()
  }

  const onChangeDate = (e) => {
    e.preventDefault()
    hoy = e.target.value
    getInit()
  }

  const onChangeCustom = (e) => {
    setPaginaActual({ label: 1, start: 0 });
    Inputs(e, inputs, setInputs)
  }

  useEffect(() => {
    getInit()
  }, [inputs, inputs2])


  const openDownload = (e) => {
    e.preventDefault()
    let uri = e.target.href + "?";

    if (inputs.fecha_inicio) {
      uri += "fecha_inicio=" + inputs.fecha_inicio + "&"
    }
    if (inputs.fecha_fin) {
      uri += "fecha_fin=" + inputs.fecha_fin + "&"
    }
    if (inputs.filter) {
      uri += "filter=" + inputs.filter + "&"
    }
    if (Object.entries(inputs2).length > 0) {
      Object.entries(inputs2).map((row, key) => {
        if (row[1] && row[1].length > 0) {
          row[1].map((row2, key2) => {
            return uri += row2.name + "[]=" + row2.value + "&"
          })
        } else {
          return false
        }
      })
    }
    //console.log(uri);
    window.open(uri)
  }

  const deleteUser = (id) => {
    PostAsync("Users", "deleteUser", { idUser: id }).then((resp) => {
      getInit()
    })
  }

  const deleteUserMessage = (id) => {
    context.setModalShow({
      show: true,
      message: <div className="text-center">
        <div className="mb-2">¿Estás seguro que deseas eliminar el usuario?</div>
        <div className="col ml-auto mr-auto" >
          <div className="col-2 btn btn-warning btn-block me-2" onClick={() => (deleteUser(id), context.setModalShow({ show: false }))}>Si</div>
          <div className="col-2 btn btn-secondary btn-block" onClick={() => context.setModalShow({ show: false })}>No</div>
        </div>
      </div>,
      size: ""
    })

  }

  const confirmState = (id) => {
    PostAsync("Inventario", "changeStatusItem", { idProduct: id }).then((resp) => {
      getInit()
    })
  }

  const handleSwitchChange = (id) => {
    context.setModalShow({
      show: true,
      message: <div className="text-center">
        <div className="mb-2">¿Estás seguro que deseas cambiar el estado?</div>
        <div className="col ml-auto mr-auto" >
          <div className="col-2 btn btn-warning btn-block me-2" onClick={() => (confirmState(id), context.setModalShow({ show: false }))}>Si</div>
          <div className="col-2 btn btn-secondary btn-block" onClick={() => context.setModalShow({ show: false })}>No</div>
        </div>
      </div>,
      size: ""
    })


  };

  const confirmDelete = (id) => {
    PostAsync("Inventario", "DeleteItemProduct", { idProduct: id }).then((resp) => {
      getInit()
    })
  }

  const DeleteProduct = (id) => {
    context.setModalShow({
      show: true,
      message: <div className="text-center">
        <div className="mb-2">¿Estás seguro que deseas eliminar el producto?</div>
        <div className="col ml-auto mr-auto" >
          <div className="col-2 btn btn-warning btn-block me-2" onClick={() => (confirmDelete(id), context.setModalShow({ show: false }))}>Si</div>
          <div className="col-2 btn btn-secondary btn-block" onClick={() => context.setModalShow({ show: false })}>No</div>
        </div>
      </div>,
      size: ""
    })


  };

  const TableRow = ({ td, totalizacion_general, precio_totalizado_final_string, peso_totalizado_final_string, cantidad_totalizado_final_string, props }) => {
    if (td.length === 0) return false;
  
    const isSexo = td[8]?.label === "Sexo";
    const isPagado = td.some(row => row.label === "Pagado");
    console.log(td);
    const getTotalString = (key, row) => {
      if ((td.length - 1) === key && precio_totalizado_final_string) return precio_totalizado_final_string;
      if ((td.length - 4) === key && peso_totalizado_final_string) return peso_totalizado_final_string ;
      if ((td.length - 5) === key && cantidad_totalizado_final_string) return cantidad_totalizado_final_string;
    

      if (row.total) {
        return totalizacion_general[row.value + "_string"] || 
               totalizacion_general[row.value + "_format"] || 
               totalizacion_general[row.value] || false;
      }
  
      return false;
    };
  
    return (
      <tr style={{ backgroundColor: '#2B3361' }} className={`p-10 text-end ${isSexo ? 'bg-primary' : isPagado ? '' : 'bg-primary'}`}>
        {td.map((row, key) => (
          <th style={{ color: 'white', backgroundColor: '#2B3361' }} className="text-center" key={key}>
            {getTotalString(key, row)}
          </th>
        ))}
      </tr>
    );
  };

  const objetoSerializado = encodeURIComponent(JSON.stringify(inputs2));

  const filterOptions = [
    {
      type: 'input',
      name: 'fecha_inicio',
      title: 'Fecha desde',
      placeholder: 'Fecha desde',
      inputType: 'date',
      required: true
    },
    {
      type: 'input',
      name: 'fecha_fin',
      title: 'Fecha hasta',
      placeholder: 'Fecha hasta',
      inputType: 'date',
      required: true
    },
    {
      type: 'selector',
      name: 'semana',
      title: 'Semana',
      selectDefault: 'Semana',
      data: semanas,
    },
    {
      type: 'selector',
      name: 'proveedor_id',
      title: 'Proveedor',
      selectDefault: 'Proveedor',
      data: proveedores
    },
    // {
    //   type: 'selector',
    //   name: 'marca_id',
    //   title: 'Marca',
    //   selectDefault: 'Marca',
    //   data: marcas
    // }
  ];


  return <>
    <div className="row justify-content-end">
      <BotonVolverHome />
      {props.fecha ? <>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-center">
          <input type="date" name="fecha" className="form-control" defaultValue={hoy} onChange={onChangeDate} />
        </div>
      </> : false}
      {props.search === undefined ? <div className="col-12 col-sm-6 mb-3 text-end">
        <InputGroup className={props.filterCustom ? "mb-3" : "mb-3 d-none"}>
          <FormControl
            defaultValue={filter}
            name="filter"
            onChange={onChangeCustom}
            placeholder="Buscar"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          {/* {props.add !== undefined && parseInt(context.user.tipo_usuario_id) <= 6 ? <>{<props.add style={props.params.tipo_usuario_id == 2 && parseInt(context.user.tipo_usuario_id) == 6 && isUsuarios ? {pointerEvents: 'none'}: {}} className={ props.params.tipo_usuario_id == 2 && parseInt(context.user.tipo_usuario_id) == 6 && isUsuarios ? "btn btn-secondary" : "btn btn-primary"} to={(props.toAdd === undefined) ? "/Usuarios/Usuario/New/" + props.params.tipo_usuario_id :  props.toAdd} > */}
          {props.add !== undefined && parseInt(context.user.tipo_usuario_id) <= 6 ? <>{<props.add style={props.params.tipo_usuario_id == 2 && parseInt(context.user.tipo_usuario_id) == 6 && isUsuarios ? {pointerEvents: 'none'}: {}} className={ props.params.tipo_usuario_id == 2 && parseInt(context.user.tipo_usuario_id) == 6 && isUsuarios ? "btn btn-secondary" : "btn btn-primary"} to={(props.toAdd === undefined) ? "/Usuarios/Usuario/New/" + props.params.tipo_usuario_id :  props.toAdd} >
            Agregar
          </props.add>}</> : false}
          {props.toExport ? <a onClick={openDownload} className="btn btn-primary me-2" href={props.toExport} target="_blank">
            Exportar
          </a> : false}
          {props.toPDF ? <a onClick={openDownload} className="btn btn-secondary" href={props.toPDF} target="_blank">
            PDF
          </a> : false}
        </InputGroup>
        <div className={!props.filterCustom ? "mb-3" : "mb-3 d-none"}>
          {props.add !== undefined && parseInt(context.user.tipo_usuario_id) <= 6 && !props.papelera ? <>{<props.add className="btn btn-primary" to={(props.toAdd === undefined) ? "/Usuarios/Usuario/Edit/0/" + parameters.value : props.toAdd}>
            Agregar
          </props.add>}
          </> : false}
          {props.toExport ? <a onClick={openDownload} className="btn btn-primary me-2" href={props.toExport} target="_blank">
            Exportar
          </a> : false}
          {props.toPDF ? <a onClick={openDownload} className="btn btn-secondary" href={props.toPDF} target="_blank">
            PDF
          </a> : false}
        </div>
      </div> : false}

      {props.downloadExcel && !props.papelera ? <>
        <div className="col-12 col-sm-2 mb-3 text-center">
          <a href={props.downloadExcel} className="btn btn-primary" target="_blank">Descargar Plantilla</a>
        </div>
      </> : false}
      {props.uploadExcel && !props.papelera ? <>
        <UploadExcel />
      </> : false}
    </div>
    {props.customFilters && !reset ? <>
      <div className="row bg-gray">
        <div className="col-12">
          <b>Filtros de búsqueda</b>
        </div>
        {/* <div className="col-12">
          <FilterList
            module="compras"
            onFilterChange={onFilterChange}
            filterOptions={filterOptions}
          />
        </div> */}
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            defaultValue={inputs.fecha_inicio}
            title="Fecha desde"
            placeholder="Fecha desde"
            type="date"
            name="fecha_inicio"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            defaultValue={inputs.fecha_fin}
            title="Fecha hasta"
            placeholder="Fecha hasta"
            type="date"
            name="fecha_fin"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            defaultValue={inputs.semana}
            name="semana"
            title="Semana"
            selectDefault="Semana"
            data={semanas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Usuarios/Lista"
            name="proveedor_id"
            title="Proveedor"
            selectDefault="Proveedor"
            data={proveedores}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Inventario/ListadoMarcas"
            name="marca_id"
            title="Marca"
            selectDefault="Marca"
            data={marcas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Usuarios/Lista"
            name="estatus"
            title="Estado"
            selectDefault="Estado"
            data={[{ label: "Abiertas", value: "0" }, { label: "Cerradas", value: "1" }]}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
      </div>
    </> : false}
    {
      // console.log(props.metodo)
    }
    {props.customFiltersI && !reset ? <>
      <div className="row bg-gray">
        <div className="col-12 ">

          {props.metodo === "ListadoComprasFiltro" ?
            <b>Filtros de búsqueda en compras</b>
            :
            <b>Filtros de búsqueda en pagos</b>
          }
        </div>
        <div className="col-12 col-sm-1 mb-3 pt-1 text-left">
          <Input
            defaultValue={inputs.fecha_inicio}
            title="Fecha desde"
            placeholder="Fecha desde"
            type="date"
            name="fecha_inicio"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-1 mb-3 pt-1 text-left">
          <Input
            defaultValue={inputs.fecha_fin}
            title="Fecha hasta"
            placeholder="Fecha hasta"
            type="date"
            name="fecha_fin"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-1 mb-3 pt-1 text-left">
          <SelectorMultiple
            name="semestre"
            title="Semestre"
            selectDefault="Semestre"
            data={[{ label: "Primero", value: "1" }, { label: "Segundo", value: "2" },]}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        {parseInt(context.user.tipo_usuario_id) <= 6 ? <div className="col-12 col-sm-1 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            name="semana"
            title="Semana"
            selectDefault="Semana"
            data={semanas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div> : false}
        {parseInt(context.user.tipo_usuario_id) <= 6 ?
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <SelectorMultiple
              url="/Usuarios/Lista"
              name="proveedor_id"
              title="Proveedor"
              selectDefault="Proveedor"
              data={proveedores}
              inputs={inputs2}
              setInputs={setInputs2}
              className="form-control"
            />
          </div> : false}
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Inventario/ListadoProductos"
            name="op_inventario_items_id"
            title="Producto"
            selectDefault="Producto"
            data={productos}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Inventario/ListadoMarcas"
            name="marca_id"
            title="Marca"
            selectDefault="Marca"
            data={marcas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        {parseInt(context.user.tipo_usuario_id) <= 6 ? <div className="col-12 col-sm-1 mb-3 pt-1 text-left">
          <SelectorMultiple
            name="es_asociado"
            title="Tipo"
            selectDefault="Tipo"
            data={[{ label: "Asociado", value: 1 }, { label: "No asociado", value: 0 },]}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div> : false}

        {/* <div className="col-12 col-sm-1 mb-3 pt-1 text-left">
          <SelectorMultiple
            name="sexo_id"
            title="Sexo"
            selectDefault="Sexo"
            data={[{ label: "M", value: "1" }, { label: "F", value: "0" },]}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div> */}

        {/* <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <SelectorMultiple
            name="planta"
            title="Planta"
            selectDefault="Planta"
            data={plantas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div> */}

        {props.metodo === "ListadoPagosEfectuadosFiltro" ?
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <FormControl
              defaultValue={filter}
              name="filter"
              onChange={onChangeCustom}
              placeholder="Buscador Comprobante"
              aria-describedby="basic-addon2"
            />
          </div> :
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <FormControl
              defaultValue={filter}
              name="filter"
              onChange={onChangeCustom}
              placeholder="Buscador Consecutivo"
              aria-describedby="basic-addon2"
            />
          </div>
        }
        {props.metodo === "ListadoPagosEfectuadosFiltro" ? "" :
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <SelectorMultiple
              name="pago_id"
              title="Pago"
              selectDefault="Pago"
              data={[{ label: "Efectuado", value: "1" }, { label: "A realizar", value: "0" },]}
              inputs={inputs2}
              setInputs={setInputs2}
              className="form-control"
            />
          </div>
        }
        {//yeah I know it could be better, if you want to complain then just make it better
        }
        {props.metodo !== "ListadoPagosEfectuadosFiltro" && (
          <>
          <div className="col-12 col-sm-1 mb-3 pt-1 text-left">
            <Input
              defaultValue={inputs.pago_fecha_inicio}
              title="Fecha de Pago desde"
              placeholder="Fecha de Pago desde"
              type="date"
              name="pago_fecha_inicio"
              className="form-control"
              onChange={onChangeCustom}
              required={true}
            />
          </div>
          <div className="col-12 col-sm-1 mb-3 pt-1 text-left">
            <Input
              defaultValue={inputs.pago_fecha_fin}
              title="Fecha de Pago hasta"
              placeholder="Fecha de Pago hasta"
              type="date"
              name="pago_fecha_fin"
              className="form-control"
              onChange={onChangeCustom}
              required={true}
            />
          </div>
          </>
        )}
        <br />
      </div>
      {/* {console.log(onChangeCustom)}  */}
    </> : false}
    {props.customFiltersI0 && !reset ? <>
      <div className="row bg-gray">
        <div className="col-12 ">
          <b>Filtros de búsqueda en compras y ventas</b>
        </div>
        <div className="col-12 col-sm-4 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Inventario/ListadoProductos"
            name="op_inventario_items_id"
            title="Producto"
            selectDefault="Producto"
            data={productos}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-4 mb-3 pt-1 text-left">
          {props.toExport ? <a onClick={openDownload} className="btn btn-primary text-left me-2" href={props.toExport} target="_blank">
            Exportar
          </a> : false}
          {props.toPDF ? <a onClick={openDownload} className="btn btn-secondary" href={props.toPDF} target="_blank">
            PDF
          </a> : false}
        </div>
      </div>
    </> : false}
    {/* {console.log(inputs)} */}
    {props.customFiltersI2 && !reset ? <>
      <div className="row bg-gray">
        <div className="col-13 ">
          <b>Filtros de búsqueda en ventas</b>
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            defaultValue={inputs.fecha_inicio}
            title="Fecha desde"
            placeholder="Fecha desde"
            type="date"
            name="fecha_inicio"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            defaultValue={inputs.fecha_fin}
            title="Fecha hasta"
            placeholder="Fecha hasta"
            type="date"
            name="fecha_fin"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            defaultValue={inputs.semana}
            name="semana"
            title="Semana"
            selectDefault="Semana"
            data={semanas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        {context.user.tipo_usuario_id == "4" ? false
          : <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
            <SelectorMultiple
              url="/Usuarios/Lista"
              name="cliente_id"
              title="Cliente"
              selectDefault="Cliente"
              data={clientes}
              inputs={inputs2}
              setInputs={setInputs2}
              className="form-control"
            />
          </div>}
        <div className="col-12 col-sm-3 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Inventario/ListadoProductos"
            name="op_inventario_items_id"
            title="Producto"
            selectDefault="Producto"
            data={productos}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        {context.user.tipo_usuario_id == "4" ? false :
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <SelectorMultiple
              url="/Inventario/ListadoMarcas"
              name="marca_id"
              title="Marca"
              selectDefault="Marca"
              data={marcas}
              inputs={inputs2}
              setInputs={setInputs2}
              className="form-control"
            />
          </div>}
        <div className="col-sm-3 mb-3 pt-1 text-left">
          <FormControl
            defaultValue={filter}
            name="filter"
            onChange={onChangeCustom}
            placeholder="Buscador Consecutivo"
            aria-describedby="basic-addon2"
          />
        </div>
      </div>
    </> : false}
    {props.customFilters2 && !reset ? <>
      <div className="row bg-gray">
        <div className="col-12 ">
          <b>Filtros de búsqueda</b>
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            defaultValue={inputs.fecha_inicio}
            title="Fecha desde"
            placeholder="Fecha desde"
            type="date"
            name="fecha_inicio"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            defaultValue={inputs.fecha_fin}
            title="Fecha hasta"
            placeholder="Fecha hasta"
            type="date"
            name="fecha_fin"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        {parseInt(context.user.tipo_usuario_id) == "2" || parseInt(context.user.tipo_usuario_id) == "6" ? <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            name="semana"
            title="Semana"
            selectDefault="Semana"
            data={semanas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div> : false}
        {parseInt(context.user.tipo_usuario_id) == "2" || parseInt(context.user.tipo_usuario_id) == "6" ? <div className="col-12 col-sm-3 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            url="/Usuarios/Lista"
            name="cliente_id"
            title="Cliente"
            selectDefault="Cliente"
            data={clientes}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div> : false}
        <div className="col-12 col-sm-3 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Inventario/ListadoProductos"
            name="op_inventario_items_id"
            title="Producto"
            selectDefault="Producto"
            data={productos}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        {props.metodo == "ListadoVentas" &&
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <SelectorMultiple
              url="/Usuarios/Lista"
              name="estatus"
              title="Estado"
              selectDefault="Estado"
              data={[{ label: "Abiertas", value: "0" }, { label: "Cerradas", value: "1" }]}
              inputs={inputs2}
              setInputs={setInputs2}
              className="form-control"
            />
          </div>}
      </div>
    </> : false}
    <div className="row">
      <div className="col-12 d-block d-sm-none">
        {/* {console.log("datadata",data)} */}
        <TableResponsive data={data} columnas={props.td} keys="token" />
      </div>
      <div className="col-12 d-none d-lg-block d-md-block d-sm-block">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {props.td.length > 0 ? <>
                  {props.td.map((row, key) => {
                    return <th key={key}
                      className={props.td.length === key + 1 &&
                        row.label !== 'Acciones' ? "text-center" : row.classNameHeader ? row.classNameHeader : row.label === 'Acciones' ? row.classNameHeader : ""}>
                      {row.label}{row.ventas === true ? <><a className="ms-1" target="" href={`${Config.ConfigApirest}Excel/Inventario/WordOfficeVentaGlobal/token:${context.user.token}`}><FontAwesomeIcon icon={faFileExcel} style={{ color: 'green' }}/></a></>: null}
                    </th>
                  })}
                </> : false}
              </tr>
            </thead>
            {open ? <tbody>
              {total_rows_empty === true ? <>
                <tr>
                  <td colSpan={props.td.length} className="text-center">
                    Sin datos.
                  </td>
                </tr>
              </> : total_rows_empty === false ? <>
                <tr>
                  <td colSpan={props.td.length} className="text-center">
                    Sin resultados
                  </td>
                </tr>
              </> : <>
                {
                  data.length === 0 ?
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Sin resultados...</td>
                      <td></td>
                      <td></td>
                    </tr> : data.map((row, key) => {
                      return <tr key={key}>
                        {
                          props.td.map((row2, key2) => {
                            // console.log(row)
                            return <td key={key2} style={row[row2.estatus] == 1 ? styleFont : styleNone} className={row2.className}>
                              {
                                (row2.value !== "events") ? row2.value === "estatus_pruduct" ?
                                  <div className="form-check form-switch">
                                    <input key={key2} className="form-check-input" type="checkbox" role="switch" id={"product" + row.op_inventario_items_id} onClick={() => { handleSwitchChange(row.op_inventario_items_id) }} checked={row[row2.value] === "1" ? true : false}></input>
                                  </div> : row2.value === "delete_pruduct" ?
                                    <div className="">
                                      <div key={key2} className="btn btn-danger"  id={"product" + row.op_inventario_items_id} onClick={() => { DeleteProduct(row.op_inventario_items_id) }} checked={row[row2.value] === "1" ? true : false}>Eliminar</div>
                                    </div> :
                                    <div className={row[row2.value]}>
                                      {row2.value === 'iva' ?
                                        // row[row2.value]: row[row2.value]
                                        `${htmlToReactParser.parse(row[row2.value]) * 1}` :
                                        htmlToReactParser.parse(row[row2.value])
                                      }
                                    </div>
                                  :
                                  <div className="p-1">
                                    {row2.icons.map((row3, key3) => {
                                      // console.log(row3);
                                      if (row3.public !== undefined && row3.public === false) {
                                        return false;
                                      }
                                      //if (row3.url!==undefined&&row3.event===undefined&&row3.keys===undefined) {
                                      if (row3.url !== undefined && row3.keys === undefined) {

                                        if (row.label === undefined) {
                                          return <row3.NavLink className="me-2" to={row3.url + row.token} key={key3} title={row3.label}>
                                            {row3.icon}
                                          </row3.NavLink>
                                        } else {
                                          if ((row3.label == "Editar" && parseInt(context.user.tipo_usuario_id) !== 6)  || (row3.label == "Editar" && parseInt(context.user.tipo_usuario_id) === 6 && props.params.tipo_usuario_id !== 2)) {
                                            return <>
                                              <row3.NavLink className="btn btn-warning me-2" to={row3.url + row.token + "/" + row.label.replace(/\./g, "") } key={key3} title={row3.label}>
                                                {row3.icon}
                                              </row3.NavLink>
                                            </>
                                          } else if (row3.label === "Descargar información") {
                                            //   return <>
                                            //   <a onClick={openDownload} className="col btn btn-primary text-left me-3" href={row3.url +"/idUser:"+row.token } target="_blank">Excel</a> 
                                            // </> 
                                          } else if (row3.label === "Eliminar usuario" && context.user.tipo_usuario_id === "2") {
                                            return <>
                                              <button onClick={() => { deleteUserMessage(row.usuario_id) }} className="col btn btn-primary text-left me-3" target="_blank">Eliminar</button>
                                            </>
                                          }

                                        }
                                      } else if (row3.url !== undefined && row3.keys !== undefined) {
                                        // return  <row3.NavLink className="me-2" to={row3.url+row.op_inventario_items_id+"/"+inputs.fecha_recibido+"/"+inputs.fecha_fin} key={key3} title={row3.label}>
                                        //           {row3.icon}
                                        //         </row3.NavLink>
                                        return <row3.NavLink className="me-2" to={row3.url + row.op_inventario_items_id} key={key3} title={row3.label}>
                                          {row3.icon}
                                        </row3.NavLink>
                                      } else if (row3.eventImport !== undefined) {
                                        return <div className="btn btn-primary btn-sm me-2" key={key3} title={row3.label} onClick={row3.eventImport}>
                                          {row3.icon} {row3.label}
                                        </div>
                                      } else {
                                        return <div className="cursor-pointer text-danger" onClick={() => { changeEstatus(row) }} key={key3}>
                                          {row3.icon}
                                        </div>
                                      }
                                    })}
                                  </div>
                              }
                            </td>
                          })
                        }
                      </tr>
                    })
                }
              </>}
            </tbody> : false}
            {props.total ? <>
              <tfoot>
                <tr className={"p-10 text-end bg-primary"} style={{backgroundColor:'#2B3361'}}>
                  {props.td.length > 0 ? <>

                    {props.td.map((row, key) => {
                      // console.log(row);
                      return <th   style={{ color: 'white', backgroundColor:'#2B3361' }} className="text-center" key={key}>
                        {/* {console.log(totalizacion)} */}
                        {

                          row.total && totalizacion[row.value + "_string"] ?
                            <>
                              {totalizacion[row.value + "_string"]}
                            </> :
                            row.total && totalizacion[row.value + "_format"] ?
                              <>
                                {totalizacion[row.value + "_format"]}
                              </>
                              :
                              row.total && totalizacion[row.value] ? <>
                                {totalizacion[row.value]}
                              </> :
                                false
                        }
                      </th>
                    })}
                  </> : false}
                </tr>
                {props.td.length > 0 ? <>
                  {
                     !totalizacion.venta  && !totalizacion.compra && < TableRow td={props.td} totalizacion_general ={totalizacion_general} precio_totalizado_final_string ={precio_totalizado_final_string} peso_totalizado_final_string ={peso_totalizado_final_string} cantidad_totalizado_final_string={cantidad_totalizado_final_string}  props={props} />
                  }
                </> : false}
              </tfoot>
            </> : false}
          </table>
        </div>
      </div>
      <div className="col-12 mt-3">
        {
          !totalizacion.venta  && !totalizacion.compra && llevaPaginacion == undefined && <Paginator paginas={paginas} paginaActual={paginaActual} setPaginaActual={handleSetPaginaActual} />
        }
        
      </div>
    </div>
  </>
}
export default App
