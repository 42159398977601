import React, { useState, useEffect } from "react";
import StateContext from "../../helpers/ContextState";
import { Inputs, PostAsync, formatoFecha } from "../../helpers/Functions";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { NavLink, useHistory } from "react-router-dom";
import Filtros from "../../components/Filtros";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Select from "../../components/Select";
import Input from "../../components/InputSimple";
import Config from "../../helpers/Config";
import Spinner from "react-bootstrap/Spinner";

let hoy = formatoFecha(new Date());
hoy = formatoFecha(new Date(hoy));
// console.log(hoy);
let items_productos_fijo = {};
let inputs_outsource = {};
let op_inventario_items_id_outsource = 0;

const App = (props) => {
  let history = useHistory();
  const params = useParams();
  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({ peso: 0, fecha_recibido: hoy });
  const [data, setData] = useState([]);
  const [itemsProductos, setItemsProductos] = useState({});
  const [productos, setProductos] = useState([]);
  const [reset, setReset] = useState(false);
  const [pesoDisabled, setPesoDisabled] = useState(true);
  const [undDisabled, setUndDisabled] = useState(true);
  const [totalizacion, setTotalizacion] = useState({});
  const [op_inventario_items_id, set_op_inventario_items_id] = useState(0);
  const [inputs2, setInputs2] = useState({});
  const [historial, setHistorial] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [info, setInfo] = useState(false);
  const [productoSelect, setProductoSelect] = useState([]);
  const [resultado, setResultado] = useState(false);

  const [resetCorregir, setResetCorregir] = useState(false);
  const [resetFecha, setResetFecha] = useState(false);

  useEffect(() => {
    if (reset) {
      setInputs([]);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (params.id && params.id !== "New") {
      setItemsProductos({});
      PostAsync(
        "Inventario",
        "ComprasItems",
        { id: params.id },
        { ...context }
      ).then((response) => {
        if (response.data) {
          setData(response.data);
          items_productos_fijo = response.data;
          setItemsProductos(response.data);
        }
        if (response.totalizacion) {
          setTotalizacion(response.totalizacion);
        }
        if (response.info) {
          setInfo(response.info);
          console.log("response info thingy",response.info)
        }
      });
    }
  }, [params]);
  useEffect(() => {
    let mounted = true;
    if (params.id !== 0) {
      getInit(mounted);
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getInit = (mounted) => {
    PostAsync("Inventario", "Compras", { id: "New" }, { ...context }).then(
      (response) => {
        if (response.data !== undefined && mounted) {
          setProductos(response.productos);
        }
        if (response.productos !== undefined && mounted) {
          setProductos(response.productos);
        }

        setInputs((prevState) => ({
          ...prevState,
          fecha_recibido: inputs.fecha_recibido ? inputs.fecha_recibido : hoy,
        }));
      }
    );
    PostAsync("Inventario", "SearchMarcas", { id: "New" }, { ...context }).then(
      (response) => {
        if (response.marcas !== undefined && mounted) {
          setMarcas(response.marcas);
        }
        setInputs((prevState) => ({
          ...prevState,
          marca_id: inputs.marca_id ? inputs.marca_id : 0
        }));
    });
    params.id &&
      PostAsync(
        "Inventario",
        "GetCambiosCompra",
        { id: params.id },
        { ...context }
      ).then((response) => {
        setHistorial(response.data);
      });
  };

  // useEffect(() => {
  //   if (inputs.op_inventario_items_id) {
  //     // setInputs(prevState => ({ ...prevState, peso: "" }))
  //     setUndDisabled(false);
  //   }
  //   if (
  //     inputs.peso !== undefined &&
  //     parseInt(inputs.peso) >= 0 &&
  //     resultado.unidad_medida_id != "2"
  //   ) {
  //     setPesoDisabled(false);
  //     // setInputs(prevState => ({ ...prevState, peso: 0 }))
  //   }
  //   // console.log("unidad",inputs.unidad_medida);
  //   // console.log("inputs.peso",inputs.peso);
  // }, [inputs.op_inventario_items_id]);
  // useEffect(() => {
  //   if (inputs.op_inventario_items_id) {
  //     // setInputs(prevState => ({ ...prevState, peso: "" }))
  //     setUndDisabled(false);
  //   }
  //   if (
  //     inputs.peso !== undefined &&
  //     parseInt(inputs.peso) >= 0 &&
  //     resultado.unidad_medida_id != "2"
  //   ) {
  //     setPesoDisabled(false);
  //     // setInputs(prevState => ({ ...prevState, peso: 0 }))
  //   }
  //   // console.log("unidad",inputs.unidad_medida);
  //   // console.log("inputs.peso",inputs.peso);
  // }, [inputs.op_inventario_items_id]);

  const onChangeFilter = (e) => {
    if (e.target.value === "") {
      setItemsProductos(items_productos_fijo);
    } else {
      setItemsProductos({});
      onChangeFilterSubmit();
    }
  };

  const onChangeFilterSubmit = () => {
    PostAsync(
      "Inventario",
      "ComprasFilter",
      {
        ...inputs_outsource,
        filtroMultiple: JSON.stringify(inputs2),
        id: params.id,
      },
      { ...context }
    ).then((response) => {
      if (response.data) {
        setItemsProductos(response.data);
      } else {
        setItemsProductos({});
      }
    });
  };

  useEffect(() => {
    if (
      inputs2.semana ||
      inputs2.proveedor_id ||
      inputs2.marca_id ||
      inputs2.op_inventario_items_id
    ) {
      onChangeFilterSubmit();
    }
  }, [inputs2]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        context.setModalShow({
          show: false,
          message: "",
          size: "sm",
        });
      }
    };

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [context]);

  const onChange = (e) => {
    // console.log(e.target.value);
    if (e.target.name === "marca") {
      //inputs.marca_string = e.target.value.split("&", 2)[1];
      inputs.marca_id = e.target.value.split("&", 2)[0];
    }
    if (e.target.name === "op_inventario_items_id") {
      const resultado = productos.find(
        (search) => search.value === e.target.value
      );
      setResultado(resultado);
      console.log("resultado", resultado);
      if (resultado.unidad_medida_id === "1") {
        setPesoDisabled(false);
        setUndDisabled(false);//Disable inputs depending on product type
        //inputs.cantidad = '0';
        //document.getElementById('cantidadd').value = '0';
      } else {
        setPesoDisabled(true);
        setUndDisabled(false);
        inputs.peso = '0';
        document.getElementById('pesoo').value = '0';
      }
    }
    setInputs({ ...inputs, fecha_recibido: e.target.value });
    
    inputs_outsource = { ...inputs };
    if (
      e.target.name === "fecha_inicio" ||
      e.target.name === "fecha_fin" ||
      e.target.name === "semana" ||
      e.target.name === "proveedor_id" ||
      e.target.name === "marca_id" ||
      e.target.name === "filter"
    ) {
      inputs_outsource[e.target.name] = e.target.value;
      onChangeFilter(e);
    }
    Inputs(e, inputs, setInputs);
    console.log("inputs: ",inputs)
  };

  function formatearFecha(fecha, formato) {
    // console.log(fecha);

    // Verificar si la fecha es un objeto Date
    if (!(fecha instanceof Date)) {
      // Intentar convertir la cadena de fecha al formato "dd/MM/yyyy"
      const partesFecha = fecha.split("/");
      if (partesFecha.length === 3) {
        // Construir la fecha con un huso horario específico
        fecha = new Date(
          `${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}T00:00:00.000Z`
        );
      } else {
        // Intentar convertir la cadena de fecha al formato "yyyy-MM-dd"
        fecha = new Date(fecha);
        if (isNaN(fecha.getTime())) {
          // Si no se puede convertir la fecha, mostrar un error
          console.error("Formato de fecha no válido");
          return null;
        }
      }
    }

    // Obtener los componentes de la fecha
    const year = fecha.getUTCFullYear();
    const month = fecha.getUTCMonth() + 1; // Sumar 1 porque los meses comienzan desde 0
    const day = fecha.getUTCDate();

    // Construir la fecha en el formato requerido
    let fechaFormateada = formato
      .replace("yyyy", year)
      .replace("MM", String(month).padStart(2, "0"))
      .replace("dd", String(day).padStart(2, "0"));

    // console.log(fechaFormateada);
    return fechaFormateada;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const fechaActual = new Date();

    // Obtener el año, mes y día
    const año = fechaActual.getFullYear();
    const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript son indexados desde 0, por lo que necesitas sumar 1.
    const dia = String(fechaActual.getDate()).padStart(2, "0");

    // Formato de la fecha en "YYYY-MM-DD"
    const fechaConFormato = `${año}-${mes}-${dia}`;
    // console.log(inputs.fecha);
    // console.log(fechaConFormato);
    // console.log(inputs.fecha_recibido);
    if (formatearFecha(inputs.fecha_recibido, "yyyy-MM-dd") > fechaConFormato) {
      return context.setModalShow({
        show: true,
        size: "md",
        message: (
          <div className="text-center">
            <div className="mb-3">
              La fecha de compra es no puede ser mayor a la actual{" "}
              {`${dia}-${mes}-${año}`}
            </div>
            <div
              className="btn btn-primary mt-3"
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
              }}
            >
              Cerrar
            </div>
          </div>
        ),
      });
    }
    if (!inputs.marca) {
      return context.setModalShow({
        show: true,
        size: "sm",
        message: (
          <div className="text-center">
            <div className="mb-3">
              La marca es requerida para poder continuar.
            </div>
            <div
              className="btn btn-primary mt-3"
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
              }}
            >
              Cerrar
            </div>
          </div>
        ),
      });
    }
    if (inputs.cantidad==0||(resultado.unidad_medida_id === "1"&&inputs.peso==0)) {//Purchase must buy something i.e. Do not allow zeroes
      return context.setModalShow({
        show: true,
        size: "sm",
        message: (
          <div className="text-center">
            <div className="mb-3">
              Cantidad o peso esta en cero.
            </div>
            <div
              className="btn btn-primary mt-3"
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
              }}
            >
              Cerrar
            </div>
          </div>
        ),
      });
    }
    // setInputs({...inputs, fecha_recibido: formatearFecha(inputs.fecha_recibido, "yyyy-MM-dd")});
    inputs.marca = document.getElementById('marcaSel').value.split("&", 2)[1];
    let inputs_ = {
      ...inputs,
      id: params.id,
      fecha_recibido: formatearFecha(inputs.fecha_recibido, "yyyy-MM-dd"),
    };
    set_op_inventario_items_id(inputs.op_inventario_items_id);
    //if (op_inventario_items_id_outsource===0) {
    op_inventario_items_id_outsource = inputs.op_inventario_items_id;
    console.log("submitted",inputs_);
    PostAsync(
      "Inventario",
      "SetCompra",
      {
        ...inputs_,
        op_inventario_items_id_outsource: op_inventario_items_id_outsource,
      },
      { ...context }
    ).then((response) => {
      if (
        response !== undefined &&
        response.message !== undefined &&
        response.message.label !== undefined
      ) {
        context.setModalShow({
          show: true,
          size: "sm",
          message: (
            <div className="text-center">
              <div className="mb-3">{response.message.label}</div>
              <div
                className="btn btn-primary mt-3"
                onClick={() => {
                  context.setModalShow({
                    show: false,
                    message: "",
                    size: "sm",
                  });
                  window.location.reload(); // Esto reiniciará la página
                }}
              >
                Cerrar
              </div>
            </div>
          ),
        });
      }
      if (response.data !== undefined && response.data !== "") {
        history.push("/Inventario/Compras/Edit/" + response.data);
      } else {
        // getInit(true)
      }
      // console.log(formatearFecha(inputs.fecha_recibido, "yyyy-MM-dd"));
      setInputs({
        fecha_recibido: formatearFecha(inputs.fecha_recibido, "yyyy-MM-dd"),
        cantidad: "",
        peso: "",
        op_inventario_items_id: op_inventario_items_id_outsource,
        observacion: "",
        contram: "",
      });
      //setReset(true);
      //setResetFecha(true);
      handleReset();
      const { op_inventario_items_id } = e.target.elements;
      const productoSeleccionado = op_inventario_items_id.value;
      // console.log(op_inventario_items_id)
      // handleReset();
      // getProduct()
      //document.getElementById("myForm").reset();
    });
  };
  const getProduct = (e) => {
    const { op_inventario_items_id } = e.target.elements;
    // console.log(op_inventario_items_id)
  };

  const edit = (row) => {
    if (!row.marca.includes("&")){
      row.marca=row.marca_id+"&"+row.marca+" - "+row.proveedor+" - "+row.planta
    }
    setInputs(row);
    setResultado(false);
    setResetFecha(true);
    setResetCorregir(true);
    console.log("Edit", row, inputs)
      setResultado(row);
      console.log("resultado edit", resultado);
      if (row.unidad_medida === "1") {
        setPesoDisabled(false);
        setUndDisabled(false);//Enable input on edit
        inputs.peso = row.peso;
        document.getElementById('pesoo').value = row.peso;
      } else {
        setPesoDisabled(true);
        setUndDisabled(false);
        inputs.peso = '0';
        document.getElementById('pesoo').value = '0';
      }
  };

  useEffect(() => {
    if (resetCorregir) {
      setResetCorregir(false);
    }
  }, [resetCorregir]);

  const handleClose = () => {
    PostAsync(
      "Inventario",
      "CloseCompra",
      { id: params.id },
      { ...context }
    ).then((response) => {
      if (response.error === false) {
        getInit(true);
        // window.location.reload();
      }
    });

    PostAsync(
      "Inventario",
      "UpdateInventario",
      {
        consecutive: info.consecutive,
        op_inventario_items_id_outsource: op_inventario_items_id_outsource,
      },
      { ...context }
    ).then((response) => {});
  };

  const handleReOpen = () => {
    PostAsync(
      "Inventario",
      "OpenCompra",
      { id: params.id },
      { ...context }
    ).then((response) => {
      if (response.error === false) {
        getInit(true);
      }
    });
  };
  function handleReset() {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    Array.from(document.querySelectorAll("select")).forEach(
      select => (select.value = "")
    );
  }

  const handleDelete = (row) => {
    if (row) {
      return context.setModalShow({
        show: true,
        size: "md",
        message: (
          <div className="text-center">
            <div className="mb-3">
              ¿Está seguro deseas eliminar el registro <b>{row.marca}</b>?
              <br />
              <b>{row.op_inventario_items_inventario_id}</b>
            </div>
            <div
              className="btn btn-primary mt-2 me-2"
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
                deleteRegister(row);
              }}
            >
              Si
            </div>
            <div
              className="btn btn-secondary mt-2 "
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
              }}
            >
              No
            </div>
          </div>
        ),
      });
    }
  };

  const deleteRegister = (row) => {
    PostAsync(
      "Inventario",
      "DeleteOneRegister",
      { id: row.token },
      { ...context }
    ).then((response) => {
      !response.error && getInit(true);
    });
  };

  const handleTrash = (row) => {
    return context.setModalShow({
      show: true,
      size: "md",
      message: (
        <div className="text-center">
          <div className="mb-3">
            ¿Está seguro deseas eliminar el registro <b>{row.marca}</b>?
            <br />
            <b>{row.op_inventario_items_inventario_id}</b>
          </div>
          <div
            className="btn btn-primary mt-2 me-2"
            onClick={() => {
              context.setModalShow({ show: false, message: "", size: "sm" });
              submitTrash();
            }}
          >
            Si
          </div>
          <div
            className="btn btn-secondary mt-2 "
            onClick={() => {
              context.setModalShow({ show: false, message: "", size: "sm" });
            }}
          >
            No
          </div>
        </div>
      ),
    });
  };

  const submitTrash = () => {
    PostAsync(
      "Inventario",
      "DeleteAllRegister",
      { id: params.id },
      { ...context }
    ).then((response) => {
      history.push("/Inventario/ListadoCompras");
    });
  };

  return (
    <div className="p-3">
      <Row className="mb-4">
        <Col xs="12" md="6" className="h4" style={{ marginTop: "45px" }}>
          Módulo de compra {info.funcionario && <>({info.funcionario})</>}
          {info.consecutive && <h5>Consecutivo: {info.consecutive}</h5>}
        </Col>
        <Col xs="12" md="6" className="h4 text-end">
          {Object.entries(itemsProductos) !== undefined &&
          Object.entries(itemsProductos).length > 0 &&
          Object.entries(itemsProductos)[0] !== undefined &&
          Object.entries(itemsProductos)[0][1] !== undefined &&
          Object.entries(itemsProductos)[0][1].estatus === "1" ? (
            <>
              <a
                target="_blank"
                className="btn btn-secondary  ms-2"
                href={
                  Config.ConfigApirest +
                  "PDF/Inventario/CompraPrint/" +
                  params.id +
                  "/" +
                  context.user.token
                }
              >
                PDF
              </a>
            </>
          ) : (
            false
          )}

          {props.me.subLabel !== "Papelera" &&
          params.id &&
          params.id !== "New" &&
          Object.entries(itemsProductos) !== undefined &&
          Object.entries(itemsProductos).length > 0 &&
          Object.entries(itemsProductos)[0] !== undefined &&
          Object.entries(itemsProductos)[0][1] !== undefined &&
          Object.entries(itemsProductos)[0][1].estatus === "0" ? (
            <>
              <div className="btn btn-warning ms-2" onClick={handleClose}>
                Cerrar compra
              </div>
            </>
          ) : (
            false
          )}
          {props.me.subLabel !== "Papelera" &&
          (context.privilegios["/Inventario/Compras/ReOpen"] ||
            parseInt(context.user.tipo_usuario_id) <= 6) &&
          Object.entries(itemsProductos) !== undefined &&
          Object.entries(itemsProductos).length > 0 &&
          Object.entries(itemsProductos)[0] !== undefined &&
          Object.entries(itemsProductos)[0][1] !== undefined &&
          Object.entries(itemsProductos)[0][1].estatus === "1" ? (
            <>
              <div className="btn btn-warning ms-2" onClick={handleReOpen}>
                Reabrir
              </div>
              <div className="btn btn-danger ms-2" onClick={handleTrash}>
                Borrar
              </div>
            </>
          ) : (
            false
          )}
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="new-edit"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="new-edit" title="Nueva / Editar">
          <form onSubmit={onSubmit} id="myForm">
            <Row
              className={
                data[0] !== undefined && data[0].estatus === "1"
                  ? "d-none"
                  : "mb-5"
              }
            >
              <Col xs="12" md="3" style={{ marginBottom: "20px" }}>
                {!resetCorregir ? (
                  <Select
                    value={productoSelect}
                    defaultValue={
                      inputs.op_inventario_items_id
                    }
                    url={"Inventario/ListadoProductos"}
                    title="Producto"
                    selectDefault="Producto"
                    name="op_inventario_items_id"
                    data={productos}
                    className="form-control"
                    onChange={onChange}
                    required={true}
                  />
                ) : (
                  false
                )}
              </Col>
              {/* {console.log(productoSelect)} */}
              <Col xs="12" md="3">
                <Input
                  defaultValue={
                    resetFecha == true
                      ? formatearFecha(inputs.fecha_recibido, "yyyy-MM-dd")
                      : ""
                  }
                  title="Fecha de compra"
                  placeholder="Fecha de compra"
                  type="date"
                  min={hoy}
                  name="fecha_recibido"
                  className="form-control"
                  onChange={onChange}
                  required={true}
                />
              </Col>
              <Col xs="12" md="6">
                {!resetCorregir ? (
                <Select
                  value={"MarcaSelect"}
                  defaultValue={inputs.marca}
                  url={"Inventario/SearchMarcas"}
                  title="Marca"
                  selectDefault="Marca"
                  name="marca"
                  id="marcaSel"
                  data={marcas}
                  className="form-control"
                  onChange={onChange}
                  required={true}
                />
              ) : (
                false
              )}
                <NavLink
                  className="form-control-label"
                  target="_blank"
                  to="/Inventario/ListadoMarcas"
                >
                  {" "}
                  Marca{" "}
                </NavLink>{" "}
                /{" "}
                <NavLink
                  className="form-control-label"
                  target="_blank"
                  to="/Usuarios/Lista"
                >
                  {" "}
                  Proveedor{" "}
                </NavLink>{" "}
                /{" "}
                <NavLink
                  className="form-control-label"
                  target="_blank"
                  to="/Inventario/Listado/ListadoPlantas"
                >
                  Planta
                </NavLink>
              </Col>
              <Col xs="12" md="3" style={{ marginBottom: "20px" }}>
                {!reset ? (
                  <>
                    <Input
                      min={1}
                      disabled={undDisabled}
                      defaultValue={inputs.cantidad}
                      title="Cantidad"
                      id="cantidadd"
                      placeholder="Cantidad"
                      type="number"
                      name="cantidad"
                      required={true}
                      className="form-control"
                      onChange={onChange}
                    />
                  </>
                ) : (
                  false
                )}
              </Col>
              <Col xs="12" md="3">
                {!reset ? (
                  <>
                  {/* {console.log("peso",inputs.peso)} */}
                    <Input
                      id="pesoo"
                      step={0.1}
                      defaultValue={inputs.peso}
                      disabled={pesoDisabled}
                      title="Peso (Kgs)"
                      placeholder="Peso"
                      type="number"
                      name="peso"
                      required={true}
                      className="form-control"
                      onChange={onChange}
                    />
                  </>
                ) : (
                  false
                )}
              </Col>

              <Col xs="12" md="3">
                {!reset ? (
                  <>
                    <Input
                      defaultValue={inputs.contram}
                      title="Contramarca"
                      placeholder="Contramarca"
                      type="text"
                      name="contram"
                      className="form-control"
                      onChange={onChange}
                    />
                  </>
                ) : (
                  false
                )}
              </Col>

              <Col xs="12" md="3">
                <Select
                  defaultValue={inputs.sexo}
                  title="Sexo"
                  selectDefault="Sexo"
                  name="sexo"
                  data={[
                    { label: "Masculino", value: "M" },
                    { label: "Femenino", value: "Femenino" },
                  ]}
                  className="form-control"
                  onChange={onChange}
                />
              </Col>
              <Col xs="12">
                {!reset ? (
                  <>
                    <Input
                      defaultValue={inputs.observacion}
                      title="Observación"
                      placeholder="Observación"
                      type="text"
                      name="observacion"
                      className="form-control"
                      onChange={onChange}
                    />
                  </>
                ) : (
                  false
                )}
              </Col>
              <Col xs="12" className="mt-3 text-end mb-5">
                {Object.entries(itemsProductos).length === 0 ||
                (Object.entries(itemsProductos) !== undefined &&
                  Object.entries(itemsProductos).length > 0 &&
                  Object.entries(itemsProductos)[0] !== undefined &&
                  Object.entries(itemsProductos)[0][1] !== undefined &&
                  Object.entries(itemsProductos)[0][1].estatus === "0") ? (
                  <>
                    <button type="submit" className="btn btn-primary">
                      Agregar
                    </button>
                  </>
                ) : (
                  false
                )}
              </Col>
            </Row>
          </form>

          <Row className="mb-5 mt-5">
            <Col>
              <Filtros
                onChange={onChange}
                inputs={inputs2}
                setInputs={setInputs2}
              />
            </Col>
          </Row>

          {Object.entries(itemsProductos).length > 0 ? (
            <>
              <table className="table ">
                <thead>
                  <tr>
                    <th className="text-center">Producto</th>
                    <th className="text-center">Fecha Compra</th>
                    <th className="text-center">Semana</th>
                    <th className="text-center">Marca</th>
                    <th className="text-center">Observación</th>
                    <th className="text-center">Proveedor</th>
                    <th className="text-center">Cantidad</th>
                    <th className="text-center">Peso</th>
                    <th className="text-center">Precio Medida</th>
                    <th className="text-center">Precio Total</th>
                    {params.id &&
                    params.id !== "New" &&
                    data[0] !== undefined &&
                    data[0].estatus === "0" ? (
                      <th width="10" className="text-center">
                        Edit
                      </th>
                    ) : (
                      false
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(itemsProductos).length > 0 ? (
                    <>
                      {Object.entries(itemsProductos).map((row, key) => {
                        // {console.log(row)}
                        return (
                          <tr key={key}>
                            <td className="text-center">{row[0]}</td>
                            <td className="text-center">
                              {row[1] && row[1].items ? (
                                <div key={key}>
                                  {row[1].items.map((row2, key2) => {
                                    // console.log(row2)
                                    return (
                                      <div key={key2} className="mb-4">
                                        {row2.fecha_recibido}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                false
                              )}
                            </td>
                            <td className="text-center">
                              {row[1] && row[1].items ? (
                                <div key={key}>
                                  {row[1].items.map((row2, key2) => {
                                    return row[1].unidad_medida_string ===
                                      "Kgr" ? (
                                      <div key={key2} className="mb-4">
                                        {row2.semana_uno}
                                      </div>
                                    ) : (
                                      <div key={key2} className="mb-4">
                                        {row2.semana_dos}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                false
                              )}
                            </td>
                            <td className="text-center">
                              {row[1] && row[1].items ? (
                                <div key={key}>
                                  {row[1].items.map((row2, key2) => {
                                    return (
                                      <div key={key2} className="mb-4">
                                        {row2.marca_string}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                false
                              )}
                            </td>
                            <td className="text-center">
                              {row[1] && row[1].items ? (
                                <div key={key}>
                                  {row[1].items.map((row2, key2) => {
                                    // console.log(row2)
                                    return (
                                      <div key={key2} className="mb-4">
                                        {row2.observacion}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                false
                              )}
                            </td>
                            <td className="text-center">
                              {row[1] && row[1].items ? (
                                <div key={key}>
                                  {row[1].items.map((row2, key2) => {
                                    return (
                                      <div key={key2} className="mb-4">
                                        {console.log(row2)}
                                        {row2.proveedor}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                false
                              )}
                            </td>
                            <td className="text-end">
                              {row[1] && row[1].items ? (
                                <div key={key} className="bg-secondary">
                                  {row[1].items.map((row2, key2) => {
                                    return (
                                      <div
                                        key={key2}
                                        className="mb-4 text-white pe-2"
                                      >
                                        {row2.cantidad}
                                      </div>
                                    );
                                  })}
                                  <div className="bg-blue text-white p-2">
                                    <b>{row[1].total_cantidad_string}</b>
                                  </div>
                                </div>
                              ) : (
                                false
                              )}
                            </td>
                            <td className="text-end">
                              {row[1] && row[1].items ? (
                                <div key={key} className="bg-secondary">
                                  {row[1].items.map((row2, key2) => {
                                    return (
                                      <div
                                        key={key2}
                                        className="mb-4 text-white pe-2"
                                      >
                                        {row2.peso}
                                      </div>
                                    );
                                  })}
                                  <div className="bg-blue text-white p-2">
                                    <b>{row[1].total_peso_string}</b>
                                  </div>
                                </div>
                              ) : (
                                false
                              )}
                            </td>
                            <td className="text-end">
                              {row[1] && row[1].items ? (
                                <div key={key}>
                                  {row[1].items.map((row2, key2) => {
                                    return (
                                      <div key={key2} className="mb-4">
                                        {row2.precio_string}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                false
                              )}
                            </td>
                            <td className="text-end ">
                              {row[1] && row[1].items ? (
                                <div key={key} className="bg-secondary">
                                  {row[1].items.map((row2, key2) => {
                                    return (
                                      <div
                                        key={key2}
                                        className="mb-4 text-white pe-2"
                                      >
                                        {row2.total_string}
                                      </div>
                                    );
                                  })}
                                  <div className="bg-blue text-white p-2">
                                    <b>{row[1].total_monto_string}</b>
                                  </div>
                                </div>
                              ) : (
                                false
                              )}
                            </td>

                            {params.id &&
                            params.id !== "New" &&
                            row[1] !== undefined &&
                            row[1].estatus === "0" ? (
                              <td>
                                {row[1] && row[1].items ? (
                                  <div key={key}>
                                    {row[1].items.map((row2, key2) => {
                                      return (
                                        <div key={key2} className="mb-3">
                                          <div
                                            className="btn btn-primary btn-sm me-2"
                                            onClick={() => edit(row2)}
                                          >
                                            Corregir
                                          </div>
                                          <div
                                            className="btn btn-secondary btn-sm"
                                            onClick={() => handleDelete(row2)}
                                          >
                                            Eliminar
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  false
                                )}
                              </td>
                            ) : (
                              false
                            )}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan="10">Sin resultados</td>
                      </tr>
                    </>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-end">
                      <div className="bg-dark text-white p-2">
                        <b>{totalizacion.total_general_cantidad}</b>
                      </div>
                    </td>
                    <td className="text-end">
                      <div className="bg-dark text-white p-2">
                        <b>{totalizacion.total_general_peso}</b>
                      </div>
                    </td>
                    <td></td>
                    <td className="text-end">
                      <div className="bg-dark text-white p-2">
                        <b>{totalizacion.total_general_monto}</b>
                      </div>
                    </td>
                    {params.id &&
                    params.id !== "New" &&
                    Object.entries(itemsProductos).length > 0 &&
                    Object.entries(itemsProductos)[0] !== undefined &&
                    Object.entries(itemsProductos)[0][1] !== undefined &&
                    Object.entries(itemsProductos)[0][1].estatus === "0" ? (
                      <td></td>
                    ) : (
                      false
                    )}
                  </tr>
                </tfoot>
              </table>
            </>
          ) : (
            <div className="text-center">No hay productos para mostrar</div>
          )}
        </Tab>
        <Tab
          eventKey="historical"
          title="Historial de Cambios"
          disabled={historial.length <= 0}
        >
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Fecha</th>
                <th className="text-center">Hora</th>
                <th className="text-center">Acción</th>
                <th className="text-center">Usuario</th>
              </tr>
            </thead>
            <tbody>
              {historial.map((row, key) => {
                return (
                  <tr key={key}>
                    <td className="text-center">{row.fecha_accion}</td>
                    <td className="text-center">{row.hora_accion}</td>
                    <td className="text-center">{row.accion}</td>
                    <td className="text-center">{row.funcionario}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </div>
  );
};

export default App;
