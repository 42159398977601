import React, { useState, useEffect } from "react";
import StateContext from "../../helpers/ContextState";
import Icon from "@mdi/react";
import { mdiDelete } from "@mdi/js";
import {
  Inputs,
  PostAsync,
  formatoFecha,
  format,
} from "../../helpers/Functions";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { NavLink, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "../../components/Select";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import Input from "../../components/InputSimple";
import Config from "../../helpers/Config";
import Spinner from "react-bootstrap/Spinner";
import SelectorGenerico from "../../components/SelectorGenerico";

import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../App.css";
let items_productos_fijo = {};
//Config.ConfigApirest
const hoy = new Intl.DateTimeFormat("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
}).format(Date.now());
// console.log(hoy);
let menudeo = [];

const App = (props) => {
  let history = useHistory();
  const params = useParams();
  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({
    fecha_recibido: hoy,
    fecha_inicio: null,
    fecha_fin: null,
    cantidad_productos: 0,
  });
  const [data, setData] = useState([]);
  const [marca, setMarca] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [items, setItems] = useState({});
  const [itemsAgrupados, setItemsAgrupados] = useState({});
  const [system, setSystem] = useState({});
  const [totalizacion, setTotalizacion] = useState({});
  const [productos, setProductos] = useState([]);
  const [productoSelect, setProductoSelect] = useState([]);
  const [info, setInfo] = useState(false);
  const [reset, setReset] = useState(false);
  const [param, setParam] = useState({});
  const [menudeado, setmenudeado] = useState(0);
  const [inputCantProdcut, SetinputCantProdcut] = useState(0);
  const [CantidadVender, SetCantidadVender] = useState(0);
  const [total1, setTotal1] = useState(0);
  const [total2, setTotal2] = useState(0);
  const [venta, setVenta] = useState({});
  const [historial, setHistorial] = useState([]);

  // console.log(inputs.fecha_recibido)
  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    // if (params.id && params.id!=='New') {
    //   PostAsync("Inventario","VentasItems",{id:params.id},{...context}).then((response) => {
    //     if (response.data) {
    //       setData(response.data);
    //     }
    //   })
    // }
  }, [params]);

  useEffect(() => {
    let mounted = true;
    if (params.id !== 0) {
      getInit(mounted);
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (inputs.producto) {
      PostAsync(
        "Inventario",
        "InventarioDisponible",
        { ...inputs },
        { ...context }
      ).then((response) => {
        console.log("SHT",response);
        if (response.data !== undefined && mounted) {
          if (response.data.cantidad>0){
            setProductos(response);
          } else {
            setProductos(null)
          }
          if(response.data.marca){
            setMarca(response.data.marca);
          }
        }
      });//I am actually losing my sanity
    }
    PostAsync("Inventario", "get_productosSelect", { id: "New" }, { ...context }).then((response) => {//Posting the id does nothing I believe
        if (response.data !== undefined && mounted) {
          setProductoSelect(response.data);
        }
      }
    );
    PostAsync("Users", "ListaUsuarios", { id: "New", extra: "4" }, { ...context }).then((response) => {
        if (response.data !== undefined && mounted) {
          setClientes(response.data);
        }
        setInputs((prevState) => ({
          ...prevState,
          //marca_id: inputs.marca_id ? inputs.marca_id : 0
        }));
    });

    return function cleanup() {
      mounted = false;
    };
  }, [
    inputs.producto,
    inputs.fecha_recibido,
    inputs.fecha_inicio,
    inputs.fecha_fin,
  ]);

  const getInit = (mounted) => {
    setReset(true);
    if (!params.id) {
      return false;
    }
    PostAsync(
      "Inventario",
      "Ventas",
      { id: params.id ? params.id : "New" },
      { ...context }
    ).then((response) => {
      console.log(response, "Response and ID ", params.id ? params.id : "New")
      if (response !== undefined && mounted) {
        // console.log("response.itemsAgrupados",response);
        setItemsAgrupados(response.itemsAgrupados);
        // console.log("response.itemsAgrupados",response.itemsAgrupados);
        setItems(response.items);
        // console.log("response.items",response.items)
        setVenta(response.venta);
        let inputs_ = { ...inputs, ...response.venta };
        inputs_.fecha_recibido = hoy;
        setInputs(inputs_);
        setSystem(response.system);
        setTotalizacion(response.totalizacion);
      }
    });
    // PostAsync(
    //   "Inventario",
    //   "Ventas",
    //   { id: params.id ? params.id : "New" },
    //   { ...context }
    // ).then((response) => {
    //   if (response !== undefined && mounted) {
    //     setVenta(response.venta);
    //     let inputs_ = { ...inputs, ...response.venta };
    //     inputs_.fecha_recibido = hoy;
    //     setInputs(inputs_);

    //     function parseNumber(value) {
    //       if (typeof value === 'string') {
    //         value = value.replace(/\./g, '').replace(',', '.');
    //       }
    //       return Number(value);
    //     }

    //     // Realizar el reduce para agrupar y sumar los valores
    //     let items = response.items;
    //     let agrupados = items.reduce((result, item) => {
    //       let key = `${item.op_inventario_items_id}|${item.marca}|${item.fecha_compra}`;
    //       if (!result[key]) {
    //         result[key] = { ...item };
    //       } else {
    //         result[key].cantidad += Number(item.cantidad);
    //         result[key].peso += Number(item.peso);
    //         result[key].subTotal += Number(item.subTotal);
    //         result[key].rete += Number(item.rete);
    //         result[key].reteICA += Number(item.reteICA);
    //         result[key].reteIVA += Number(item.reteIVA);
    //         result[key].iva_2 += Number(item.iva_2);
    //         result[key].total += Number(item.total);
    //         result[key].precio_individual += Number(item.precio_individual);
    //       }
    //       return result;
    //     }, {});

    //     // Convertir el objeto agrupado a un array
    //     let agrupadosArray = Object.values(agrupados);

    //     // Calcular la totalización
    //     let totalizacion = agrupadosArray.reduce((total, item) => {
    //       total.cantidad = (total.cantidad || 0) + Number(item.cantidad);
    //       total.peso = (total.peso || 0) + Number(item.peso);
    //       total.reteICA = (total.reteICA || 0) + Number(item.reteICA);
    //       total.rete = (total.rete || 0) + Number(item.rete);
    //       total.precio_individual = (total.precio_individual || 0) + Number(item.precio_individual);
    //       total.total = (total.total || 0) + Number(item.total);
    //       total.reteIVA = (total.reteIVA || 0) + Number(item.reteIVA);
    //       total.subTotal = (total.subTotal || 0) + Number(item.subTotal);
    //       total.iva_2 = (total.iva_2 || 0) + Number(item.iva_2);
    //       return total;
    //     }, {});

    //     // Formatear los valores totalizados
    //     totalizacion.cantidad_string = format(totalizacion.cantidad);
    //     totalizacion.peso_string = format(totalizacion.peso);
    //     totalizacion.reteICA_string = format(totalizacion.reteICA);
    //     totalizacion.rete_string = format(totalizacion.rete);
    //     totalizacion.precio_individual_string = format(totalizacion.precio_individual);
    //     totalizacion.total_string = format(totalizacion.total);
    //     totalizacion.reteIVA_string = format(totalizacion.reteIVA);
    //     totalizacion.subTotal_string = format(totalizacion.subTotal);
    //     totalizacion.iva_string = format(totalizacion.iva_2);

    //     setItemsAgrupados(agrupadosArray);
    //     setItems(response.items);
    //     setSystem(response.system);
    //     setTotalizacion(totalizacion);
    //   }
    // });
    params.id &&
      PostAsync(
        "Inventario",
        "get_cambios_venta",
        { id: params.id },
        { ...context }
      ).then((response) => {
        if (response !== undefined && mounted) {
          setHistorial(response.data);
        }
      });
  };

  const onChange = (e, extra) => {
    if (e.target.name=="cliente"){
      inputs.cliente_string = e.target.value.split("&", 2)[1]; 
      inputs.cliente = e.target.value.split("&", 2)[1];
      inputs.cliente_id = e.target.value.split("&", 2)[0];
    }
    if (e.target.name=="producto"){
      inputs.producto = e.target.value.split("&", 4)[2]; 
      inputs.producto_string = e.target.value.split("&", 4)[2];
      inputs.producto_name2 = e.target.value.split("&", 4)[3];
      inputs.producto_id = e.target.value.split("&", 4)[0];
      inputs.producto_id2 = e.target.value.split("&", 4)[1];
    }
    Inputs(e, inputs, setInputs);
    console.log(e.target.value, "inputs", inputs);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let inputs_ = { ...inputs, id: params.id };
    PostAsync("Inventario", "SetCompra", inputs_, { ...context }).then(
      (response) => {
        // console.log(inputs_);
        if (
          response !== undefined &&
          response.message !== undefined &&
          response.message.label !== undefined
        ) {
          context.setModalShow({
            show: true,
            size: "sm",
            message: (
              <div className="text-center">
                <div className="mb-3">{response.message.label}</div>
                <div
                  className="btn btn-primary mt-3"
                  onClick={() => {
                    context.setModalShow({
                      show: false,
                      message: "",
                      size: "sm",
                    });
                  }}
                >
                  Cerrar
                </div>
              </div>
            ),
          });
        }

        if (response.data !== undefined && response.data !== "") {
          history.push("/Inventario/Compras/Edit/" + response.data);
        } else {
          getInit(true);
        }
        setInputs({ fecha_recibido: hoy, cantidad: 1 });
        setReset(true);
        // handleReset();
        //document.getElementById("myForm").reset();
      }
    );
  };

  const edit = (row) => {
    setInputs(row);
  };

  const reloadPagina = () => {
    window.location.reload(true);
  };

  const handleCloseValid = () => {
    console.log(inputs.consecutive);
    let op_inventario_items_id_outsource = inputs.op_inventario_items_id;
    console.log("closeValid",op_inventario_items_id_outsource);
    PostAsync(
      "Inventario",
      "nuevaVenta",
      { ...inputs, CantidadVender },
      { ...context }
    ).then((response) => {
      console.log(response);
      if (response.label != "Venta realizada con exito") {
        context.setModalShow({
          show: true,
          size: "lg",
          message: (
            <div className="text-center">
              <div className="mb-3">{response.label}</div>
              <div
                className="btn btn-primary mt-3"
                onClick={() => {
                  reloadPagina();
                }}
              >
                Reintente por favor
              </div>
            </div>
          ),
        });
        getInit(true);
        reiniciarFormulario();
      } else {
        context.setModalShow({
          show: true,
          size: "lg",
          message: (
            <div className="text-center">
              <div className="mb-3">{response.label}</div>
              <div
                className="btn btn-primary mt-3"
                onClick={() => {
                  reloadPagina();
                }}
              >
                Aceptar
              </div>
            </div>
          ),
        });
        PostAsync(
          "Inventario",
          "CloseVenta",
          { id: params.id, items: JSON.stringify(items) },
          { ...context }
        ).then((response) => {
          console.log("closed",response)
          if (!response.error) {
            getInit(true);
          }
        });
      }

      if (response.data !== undefined && response.data !== "") {
        getInit(true);
      } else {
        getInit(true);
      }
      reiniciarFormulario();
    });
  };

  const handleClose = () => {
    return context.setModalShow({
      show: true,
      size: "lg",
      message: (
        <div className="text-center">
          <div className="mb-3">
            <strong>¿Está seguro de cerrar la venta?</strong>
            <br />
            <p>
              Revisa la disponibilidad de la fecha antes de cerrar la venta.
            </p>
          </div>
          <div
            className="btn btn-primary mt-2 me-2"
            onClick={() => {
              context.setModalShow({ show: false, message: "", size: "sm" });
              handleCloseValid();
            }}
          >
            Si
          </div>
          <div
            className="btn btn-secondary mt-2 "
            onClick={() => {
              context.setModalShow({ show: false, message: "", size: "sm" });
            }}
          >
            No
          </div>
        </div>
      ),
    });
  };
  const handleSave = () => {
    // return console.log(JSON.stringify(items[0].total));
    // console.log(JSON.stringify(items));
    PostAsync(
      "Inventario",
      "SaveVenta",
      // { id: params.id, items: JSON.stringify(items) },
      { id: params.id, items: JSON.stringify(items) },
      { ...context }
    ).then((response) => {
      if (!response.error) {
        getInit(true);
      }    
    });
    PostAsync(
          "Inventario",
          "UpdateInventario",
          {
            consecutive: inputs.consecutive,
            op_inventario_items_id_outsource: inputs.op_inventario_items_id,
          },
          { ...context }
        ).then((response) => {});
      //*/
  };

  const InventarioDisponible = () => {
    // return console.log(JSON.stringify(items[0].total));
    PostAsync(
      "Inventario",
      "InventarioDisponible",
      { id: params.id, items: JSON.stringify(items) },
      { ...context }
    ).then((response) => {
      // console.log(response.data);
      if (!response.error) {
        getInit(true);
      }
    });
  };

  const handleReOpen = () => {
    PostAsync(
      "Inventario",
      "OpenVenta",
      { id: params.id },
      { ...context }
    ).then((response) => {
      if (!response.error) {
        getInit(true);
      }
    });
  };

  function handleReset() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("select")).forEach(
      (select) => (select.value = "")
    );
  }

  const handleAddMenudeado = (e, row) => {
    if (e.target.checked === true) {
      menudeo.push(row);
      console.log(menudeo);
    } else if (e.target.checked === false) {
      menudeo = menudeo.filter((obj) => obj.idInventario !== row.idInventario);
      console.log(menudeo);
    }

    let total1_ = 0;
    let total2_ = 0;
    menudeo.map((row, key) => {
      total2_ += parseFloat(row.cantidad);
      total1_ += parseFloat(row.peso)
      Math.round(total1_)
      return (total1_);
    });
    setTotal1(total1_);
    setTotal2(total2_);
  };

  const handleCheckAll = (e, row) => {
    if (e.target.checked === true) {
      var checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
      menudeo = [];
      let total1_ = 0;
      let total2_ = 0;
      productos.productos.map((row, key) => {
        menudeo.push(row);
        total2_ += parseFloat(row.cantidad);
        return (total1_ += parseFloat(row.peso));
      });
      setTotal1(total1_);
      setTotal2(total2_);
    } else if (e.target.checked === false) {
      var checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      menudeo = [];
      let total1_ = 0;
      let total2_ = 0;
      setTotal1(total1_);
      setTotal2(total2_);
    }
  };

  const handleSubmitMenudeado = () => {
    let send = { ...inputs };
    send.menudeo = JSON.stringify(menudeo);

    let send__ = [];

    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(function (userItem) {
      if (userItem.value !== "on") {
        send__.push({ json: userItem.value, checked: userItem.checked });
      }
    });

    PostAsync(
      "Inventario",
      "setVentaMenudeado",
      { ...send, data_new: JSON.stringify(send__) },
      { ...context }
    ).then((response) => {
      if (response.data !== undefined && response.data !== "") {
        history.push("/Inventario/Ventas/Edit/" + response.data);
        getInit(true);
      } else {
        getInit(true);
      }
      reiniciarFormulario();
    });
  };

  const handleSubmit = (event) => {
    // Math.floor(CantidadVender);
    // Math.floor(productos.data["cantidad"])
    console.log(inputs.consecutive);
    let op_inventario_items_id_outsource = inputs.op_inventario_items_id;
    console.log("This does something I guess",op_inventario_items_id_outsource);//I don't think this does anything//turns out it does
    if (Number(CantidadVender) > Number(productos.data["cantidad"])) {
      // console.log(typeof(CantidadVender)+"...."+typeof(productos.data["cantidad"]))
      context.setModalShow({
        show: true,
        size: "sm",
        message: (
          <div className="text-center">
            <div className="mb-3">
              La cantidad <b>({CantidadVender})</b> supera la disponibilidad{" "}
              <b>({productos.data["cantidad"]})</b>
            </div>
            <div
              className="btn btn-primary mt-3"
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
              }}
            >
              Reintente por favor
            </div>
          </div>
        ),
      });
      event.target.value = "";
    } else if (Number(productos.data["cantidad"]) === 0) {
      context.setModalShow({
        show: true,
        size: "sm",
        message: (
          <div className="text-center">
            <div className="mb-3">Sin unidades en el Inventario</div>
            <div
              className="btn btn-primary mt-3"
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
              }}
            >
              Reintente por favor
            </div>
          </div>
        ),
      });
      event.target.value = "";
    } else if (CantidadVender <= 0) {
      context.setModalShow({
        show: true,
        size: "sm",
        message: (
          <div className="text-center">
            <div className="mb-3">
              No puedes vender la cantidad de <b>{CantidadVender}</b>
            </div>
            <div
              className="btn btn-primary mt-3"
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
              }}
            >
              Reintente por favor
            </div>
          </div>
        ),
      });
      event.target.value = "";
    } else {
      PostAsync(
        "Inventario",
        "setVenta",
        { ...inputs, CantidadVender, modoVenta: param.comprar, marca: marca },
        { ...context }
      ).then((response) => {
        if (response.data !== undefined && response.data !== "") {
          history.push("/Inventario/Ventas/Edit/" + response.data);
          getInit(true);
        } else {
          getInit(true);
        }
        reiniciarFormulario();
      });

      inputs.fecha_inicio = "";
      inputs.fecha_fin = "";
      // PostAsync("Inventario","nuevaVenta",{...inputs,CantidadVender},{...context}).then((response) => {
      //   if (response.data!==undefined&&response.data!=="") {
      //     getInit(true)
      //   }else {
      //     getInit(true)
      //   }
      //   reiniciarFormulario()
      // })
    }
  };

  const formatDinero = (value) => {
    return parseFloat(value).toLocaleString("en-US", { currency: "USD" });
  };

  const reiniciarFormulario = () => {
    setProductos([]);
    setParam({});
    setInputs([]);
  };

  const handleSubmitTotal = () => {
    PostAsync(
      "Inventario",
      "setVenta",
      { ...inputs, CantidadVender, modoVenta: param.comprar },
      { ...context }
    ).then((response) => {
      if (response.data !== undefined && response.data !== "") {
        history.push("/Inventario/Ventas/Edit/" + response.data);
        getInit(true);
      } else {
        getInit(true);
      }
      reiniciarFormulario();
    });
    setInputs((inputs.fecha_inicio = ""));
    setInputs(inputs, (inputs.fecha_fin = ""));
  };

  const deleteProductVenta = (idProducto) => {
    console.log("funciona");
    PostAsync(
      "Inventario",
      "deleteProductVenta",
      { idProducto: idProducto },
      { ...context }
    ).then((response) => {
      if (response.label == "Eliminado correctamente") {
        return context.setModalShow({
          show: true,
          size: "md",
          message: (
            <div className="text-center">
              <div className="mb-3">Eliminado correctamente</div>
              <div
                className="btn btn-primary mt-2 me-2"
                onClick={() => {
                  context.setModalShow({
                    show: false,
                    message: "",
                    size: "sm",
                  });
                  reloadPagina();
                }}
              >
                OK
              </div>
            </div>
          ),
        });
      }
    });
  };

  const calcular = (e, row, key, tipo) => {
    // console.log('calcular',key);
    calcularItems(e, row, key, tipo);
    let inputs_ = [...itemsAgrupados];
    // const llevaItemsAgrupados = itemsAgrupados.some(item => item.agrupados_ids.length > 1);

    if (inputs_[key]) {
      const iva = parseFloat(system.iva);
      // const iva = parseFloat(system.iva_venta);

      inputs_[key][e.target.name] = e.target.value;

      if (inputs_[key][e.target.name] === "") {
        inputs_[key][e.target.name] = 0;
      }

      /*calculo subtotal*/
      if (tipo === "precio") {
        inputs_[key]["subTotal"] =
          row.peso === "0.00"
            ? parseFloat(e.target.value) * parseFloat(row.cantidad)
            : parseFloat(e.target.value) * parseFloat(row.peso);
      } else {
        inputs_[key]["subTotal"] =
          row.peso === "0.00"
            ? parseFloat(row.precio_individual) * parseFloat(e.target.value)
            : parseFloat(row.precio_individual) * parseFloat(row.peso);
      }

      if (!inputs_[key]["subTotal"]) {
        inputs_[key]["subTotal"] = 0;
      }

      /*calculo iva*/
      if (parseFloat(inputs_[key]["iva"]) >= 0) {
        inputs_[key]["iva"] = parseFloat(inputs_[key]["subTotal"]) * iva;
      } else {
        inputs_[key]["iva"] = 0;
      }

      // if (inputs.reteiva_new==='1') {
      //   inputs_[key]["iva"]          =   parseFloat(inputs_[key]["subTotal"]) * 0.19
      // }else {
      //   inputs_[key]["iva"]          =   0
      // }
      if (
        parseFloat(system.base_retefuente_venta.replace(/\./g, "")) <=
        inputs_[key]["subTotal"]
      ) {
        inputs_[key]["rete"] =
          parseFloat(inputs_[key]["subTotal"]) *
          parseFloat(system.retefuente_venta);
      } else {
        inputs_[key]["rete"] = 0;
      }

      if (
        parseFloat(system.base_iva_venta.replace(/\./g, "")) <=
          inputs_[key]["subTotal"] &&
        parseFloat(inputs_[key]["iva"]) > 0
      ) {
        inputs_[key]["reteIVA"] =
          parseFloat(inputs_[key]["iva"]) * parseFloat(system.reteiva_venta);
      } else {
        inputs_[key]["reteIVA"] = 0;
      }

      if (
        inputs.reteICA_new === "1" &&
        parseFloat(system.base_reteica_venta.replace(/\./g, "")) <=
          inputs_[key]["subTotal"]
      ) {
        inputs_[key]["reteICA"] =
          parseFloat(inputs_[key]["subTotal"]) *
          parseFloat(system.reteica_venta);
      } else {
        inputs_[key]["reteICA"] = 0;
      }

      //console.log(inputs_[key]["subTotal"],inputs_[key]["iva"],inputs_[key]["rete"],inputs_[key]["reteIVA"],inputs_[key]["reteICA"]);

      //console.log(inputs_[key]["rete"]);

      inputs_[key]["rete_string"] = format(inputs_[key]["rete"]);

      //inputs_[key]["reteIVA_string"]  =   format(inputs_[key]["reteIVA"])

      if (inputs.reteiva_new === "1" && parseFloat(inputs_[key]["iva"]) > 0) {
        inputs_[key]["reteIVA_string"] = format(inputs_[key]["reteIVA"]);
      } else {
        inputs_[key]["reteIVA"] = 0;
        inputs_[key]["total"] = inputs_[key]["total"] - inputs_[key]["reteIVA"];
      }

      if (inputs.reteICA_new === "1") {
        inputs_[key]["reteICA_string"] = format(inputs_[key]["reteICA"]);
      } else {
        inputs_[key]["reteICA"] = 0;
        inputs_[key]["total"] = inputs_[key]["total"] - inputs_[key]["reteICA"];
      }

      if (!inputs_[key]["total"]) {
        inputs_[key]["total"] = 0;
      }

      inputs_[key]["total"] =
        inputs_[key]["subTotal"] +
        inputs_[key]["iva"] -
        inputs_[key]["rete"] -
        inputs_[key]["reteIVA"] -
        inputs_[key]["reteICA"];

      console.log(
        " SubTotal: " +
          inputs_[key]["subTotal"] +
          " IVA: " +
          inputs_[key]["iva"] +
          " RETEFUENTE: " +
          inputs_[key]["rete"] +
          " RETEIVA: " +
          inputs_[key]["reteIVA"] +
          " RETEICA: " +
          inputs_[key]["reteICA"]
      );
      inputs_[key]["subTotal_string"] = format(inputs_[key]["subTotal"]);
      inputs_[key]["iva_string"] = format(inputs_[key]["iva"]);

      inputs_[key]["total_string"] = format(inputs_[key]["total"]);
      // console.log(inputs_[key]);
    }
    setItemsAgrupados(inputs_);

    let totalizacion_ = { ...totalizacion };
    let totallizacion2 = {};
    inputs_.map((row, key) => {
      if (totallizacion2.subTotal_string === undefined) {
        totallizacion2.subTotal_string = row.subTotal;

        totallizacion2.iva_string = row.iva;

        totallizacion2.rete_string = row.rete;

        if (inputs.reteiva_new === "1" && row.reteIVA > 0) {
          totallizacion2.reteIVA_string = row.reteIVA;
        } else {
          totallizacion2.reteIVA_string = 0;
        }

        if (inputs.reteICA_new === "1" && row.reteICA > 0) {
          totallizacion2.reteICA_string = row.reteICA;
        } else {
          totallizacion2.reteICA_string = 0;
        }
        //totallizacion2.reteICA_string    = row.reteICA
        totallizacion2.total_string = row.total;
      } else {
        totallizacion2.subTotal_string +=
          row.subTotal !== undefined ? parseFloat(row.subTotal) : 0;
        totallizacion2.iva_string +=
          row.iva !== undefined ? parseFloat(row.iva_2) : 0;

        totallizacion2.rete_string +=
          row.rete !== undefined ? parseFloat(row.rete) : 0;

        if (inputs.reteiva_new === "1") {
          totallizacion2.reteIVA_string +=
            row.reteIVA !== undefined ? parseFloat(row.reteIVA) : 0;
        }

        if (inputs.reteICA_new === "1") {
          totallizacion2.reteICA_string +=
            row.reteICA !== undefined ? parseFloat(row.reteICA) : 0;
        }

        totallizacion2.total_string +=
          row.total !== undefined ? parseFloat(row.total) : 0;
      }

      return totallizacion2;
    });
    // console.log(totalizacion_.total_string);

    totallizacion2.cantidad = totalizacion_.cantidad;
    totallizacion2.peso = totalizacion_.peso;
    totallizacion2.cantidad_string = totalizacion_.cantidad_string;
    totallizacion2.peso_string = totalizacion_.peso_string;
    setTotalizacion(totallizacion2);
    // };
    // items.forEach((item) => {
    //   if (
    //     inputs_[key].agrupados_ids.includes(
    //       item.op_inventario_items_inventario_venta_id
    //     )
    //   ) {
    //     // Realizar las mismas operaciones para cada item
    //     item["subTotal"] = item["peso"] > 0 ? parseFloat(e.target.value) * parseFloat(item["peso"]) : parseFloat(e.target.value) * parseFloat(item["cantidad"]);
    //     item["iva"] = parseFloat(item["subTotal"]) * parseFloat(system.iva);
    //     item["rete"] = parseFloat(item["subTotal"]) * parseFloat(system.retefuente_venta);
    //     item["reteIVA"] = parseFloat(item["iva"]) * parseFloat(system.reteiva_venta);
    //     item["reteICA"] = parseFloat(item["subTotal"]) * parseFloat(system.reteica_venta);
    //     item["total"] = parseFloat(item["subTotal"]) + parseFloat(item["iva"]) + parseFloat(item["rete"]) + parseFloat(item["reteIVA"]) + parseFloat(item["reteICA"]);
    //   }
    // });
  }
  const calcularItems = (e, row, key, tipo) => {
    let inputs_ = [...items];
    // console.log("itemsantes", e.target.value);
    let itemsCoincidentes = inputs_.filter((item) => itemsAgrupados[key].agrupados_ids.includes(item.op_inventario_items_inventario_venta_id)).map(item => item);
    // const llevaItemsAgrupados = itemsAgrupados.some(item => item.agrupados_ids.length > 1);

    itemsCoincidentes.forEach((item) => {
      const iva = parseFloat(system.iva);

      item[e.target.name] = e.target.value;

      if (item[e.target.name] === "") {
        item[e.target.name] = 0;
      }

      /*calculo subtotal*/
      if (tipo === "precio") {
        item["subTotal"] =
          item.peso === "0.00"
            ? parseFloat(e.target.value) * parseFloat(item.cantidad)
            : parseFloat(e.target.value) * parseFloat(item.peso);
      } else {
        item["subTotal"] =
          item.peso === "0.00"
            ? parseFloat(item.precio_individual) * parseFloat(e.target.value)
            : parseFloat(item.precio_individual) * parseFloat(item.peso);
      }

      if (!item["subTotal"]) {
        item["subTotal"] = 0;
      }

      /*calculo iva*/
      if (parseFloat(item["iva"]) >= 0) {
        item["iva"] = parseFloat(item["subTotal"]) * iva;
      } else {
        item["iva"] = 0;
      }

      if (
        parseFloat(system.base_retefuente_venta.replace(/\./g, "")) <=
        item["subTotal"]
      ) {
        item["rete"] =
          parseFloat(item["subTotal"]) *
          parseFloat(system.retefuente_venta);
      } else {
        item["rete"] = 0;
      }

      if (
        parseFloat(system.base_iva_venta.replace(/\./g, "")) <=
          item["subTotal"] &&
        parseFloat(item["iva"]) > 0
      ) {
        item["reteIVA"] =
          parseFloat(item["iva"]) * parseFloat(system.reteiva_venta);
      } else {
        item["reteIVA"] = 0;
      }

      if (
        inputs.reteICA_new === "1" &&
        parseFloat(system.base_reteica_venta.replace(/\./g, "")) <=
          item["subTotal"]
      ) {
        item["reteICA"] =
          parseFloat(item["subTotal"]) *
          parseFloat(system.reteica_venta);
      } else {
        item["reteICA"] = 0;
      }

      item["rete_string"] = format(item["rete"]);

      if (inputs.reteiva_new === "1" && parseFloat(item["iva"]) > 0) {
        item["reteIVA_string"] = format(item["reteIVA"]);
      } else {
        item["reteIVA"] = 0;
        item["total"] = item["total"] - item["reteIVA"];
      }

      if (inputs.reteICA_new === "1") {
        item["reteICA_string"] = format(item["reteICA"]);
      } else {
        item["reteICA"] = 0;
        item["total"] = item["total"] - item["reteICA"];
      }

      if (!item["total"]) {
        item["total"] = 0;
      }

      item["total"] =
        item["subTotal"] +
        item["iva"] -
        item["rete"] -
        item["reteIVA"] -
        item["reteICA"];

      console.log(
        "Items normales SubTotal: " +
          item["subTotal"] +
          " IVA: " +
          item["iva"] +
          " RETEFUENTE: " +
          item["rete"] +
          " RETEIVA: " +
          item["reteIVA"] +
          " RETEICA: " +
          item["reteICA"]+
          " ImpuestoID"+
          item['impuesto_id']
      );
      item["subTotal_string"] = format(item["subTotal"]);
      item["iva_string"] = format(item["iva"]);

      item["total_string"] = format(item["total"]);

      items.forEach(itemItems => {
        if(itemItems.op_inventario_items_inventario_venta_id == item.op_inventario_items_inventario_venta_id){
          itemItems = item;
        }
      });
      // console.log(item);
    });
    // console.log("itemsCoincidentes", itemsCoincidentes);
    // };
    // items.forEach((item) => {
    //   if (
    //     inputs_[key].agrupados_ids.includes(
    //       item.op_inventario_items_inventario_venta_id
    //     )
    //   ) {
    //     // Realizar las mismas operaciones para cada item
    //     item["subTotal"] = item["peso"] > 0 ? parseFloat(e.target.value) * parseFloat(item["peso"]) : parseFloat(e.target.value) * parseFloat(item["cantidad"]);
    //     item["iva"] = parseFloat(item["subTotal"]) * parseFloat(system.iva);
    //     item["rete"] = parseFloat(item["subTotal"]) * parseFloat(system.retefuente_venta);
    //     item["reteIVA"] = parseFloat(item["iva"]) * parseFloat(system.reteiva_venta);
    //     item["reteICA"] = parseFloat(item["subTotal"]) * parseFloat(system.reteica_venta);
    //     item["total"] = parseFloat(item["subTotal"]) + parseFloat(item["iva"]) + parseFloat(item["rete"]) + parseFloat(item["reteIVA"]) + parseFloat(item["reteICA"]);
    //   }
    // });
  }
  // let inputs_ = [...itemsAgrupados];
  // if (inputs_[key]) {
  //   // console.log(system);
  //   const iva = parseFloat(system.iva);
  //   // const iva = parseFloat(system.iva_venta);

  //   inputs_[key][e.target.name] = e.target.value;

  //   if (inputs_[key][e.target.name] === "") {
  //     inputs_[key][e.target.name] = 0;
  //   }

  //   /*calculo subtotal*/
  //   if (tipo === "precio") {
  //     inputs_[key]["subTotal"] =
  //       row.peso === "0.00"
  //         ? parseFloat(e.target.value) * parseFloat(row.cantidad)
  //         : parseFloat(e.target.value) * parseFloat(row.peso);
  //   } else {
  //     inputs_[key]["subTotal"] =
  //       row.peso === "0.00"
  //         ? parseFloat(row.precio_individual) * parseFloat(e.target.value)
  //         : parseFloat(row.precio_individual) * parseFloat(row.peso);
  //   }

  //   if (!inputs_[key]["subTotal"]) {
  //     inputs_[key]["subTotal"] = 0;
  //   }

  //   /*calculo iva*/
  //   if (parseFloat(inputs_[key]["iva"]) >= 0) {
  //     inputs_[key]["iva"] = parseFloat(inputs_[key]["subTotal"]) * iva;
  //   } else {
  //     inputs_[key]["iva"] = 0;
  //   }

  //   // if (inputs.reteiva_new==='1') {
  //   //   inputs_[key]["iva"]          =   parseFloat(inputs_[key]["subTotal"]) * 0.19
  //   // }else {
  //   //   inputs_[key]["iva"]          =   0
  //   // }
  //   if (
  //     parseFloat(system.base_retefuente_venta.replace(/\./g, "")) <=
  //     inputs_[key]["subTotal"]
  //   ) {
  //     inputs_[key]["rete"] =
  //       parseFloat(inputs_[key]["subTotal"]) *
  //       parseFloat(system.retefuente_venta);
  //   } else {
  //     inputs_[key]["rete"] = 0;
  //   }

  //   if (
  //     parseFloat(system.base_iva_venta.replace(/\./g, "")) <=
  //       inputs_[key]["subTotal"] &&
  //     parseFloat(inputs_[key]["iva"]) > 0
  //   ) {
  //     inputs_[key]["reteIVA"] =
  //       parseFloat(inputs_[key]["iva"]) * parseFloat(system.reteiva_venta);
  //   } else {
  //     inputs_[key]["reteIVA"] = 0;
  //   }

  //   if (
  //     inputs.reteICA_new === "1" &&
  //     parseFloat(system.base_reteica_venta.replace(/\./g, "")) <=
  //       inputs_[key]["subTotal"]
  //   ) {
  //     inputs_[key]["reteICA"] =
  //       parseFloat(inputs_[key]["subTotal"]) *
  //       parseFloat(system.reteica_venta);
  //   } else {
  //     inputs_[key]["reteICA"] = 0;
  //   }

  //   //console.log(inputs_[key]["subTotal"],inputs_[key]["iva"],inputs_[key]["rete"],inputs_[key]["reteIVA"],inputs_[key]["reteICA"]);

  //   //console.log(inputs_[key]["rete"]);

  //   inputs_[key]["rete_string"] = format(inputs_[key]["rete"]);

  //   //inputs_[key]["reteIVA_string"]  =   format(inputs_[key]["reteIVA"])

  //   if (inputs.reteiva_new === "1" && parseFloat(inputs_[key]["iva"]) > 0) {
  //     inputs_[key]["reteIVA_string"] = format(inputs_[key]["reteIVA"]);
  //   } else {
  //     inputs_[key]["reteIVA"] = 0;
  //     inputs_[key]["total"] = inputs_[key]["total"] - inputs_[key]["reteIVA"];
  //   }

  //   if (inputs.reteICA_new === "1") {
  //     inputs_[key]["reteICA_string"] = format(inputs_[key]["reteICA"]);
  //   } else {
  //     inputs_[key]["reteICA"] = 0;
  //     inputs_[key]["total"] = inputs_[key]["total"] - inputs_[key]["reteICA"];
  //   }

  //   if (!inputs_[key]["total"]) {
  //     inputs_[key]["total"] = 0;
  //   }

  //   inputs_[key]["total"] =
  //     inputs_[key]["subTotal"] +
  //     inputs_[key]["iva"] -
  //     inputs_[key]["rete"] -
  //     inputs_[key]["reteIVA"] -
  //     inputs_[key]["reteICA"];

  //   console.log(
  //     " SubTotal: " +
  //       inputs_[key]["subTotal"] +
  //       " IVA: " +
  //       inputs_[key]["iva"] +
  //       " RETEFUENTE: " +
  //       inputs_[key]["rete"] +
  //       " RETEIVA: " +
  //       inputs_[key]["reteIVA"] +
  //       " RETEICA: " +
  //       inputs_[key]["reteICA"]
  //   );
  //   inputs_[key]["subTotal_string"] = format(inputs_[key]["subTotal"]);
  //   inputs_[key]["iva_string"] = format(inputs_[key]["iva"]);

  //   inputs_[key]["total_string"] = format(inputs_[key]["total"]);
  //   console.log(inputs_[key]);
  // }
  // // setItems(inputs_);
  // setItemsAgrupados(inputs_);

  // let totalizacion_ = { ...totalizacion };
  // let totallizacion2 = {};
  // inputs_.map((row, key) => {
  //   if (totallizacion2.subTotal_string === undefined) {
  //     totallizacion2.subTotal_string = row.subTotal;

  //     totallizacion2.iva_string = row.iva;

  //     totallizacion2.rete_string = row.rete;

  //     if (inputs.reteiva_new === "1" && row.reteIVA > 0) {
  //       totallizacion2.reteIVA_string = row.reteIVA;
  //     } else {
  //       totallizacion2.reteIVA_string = 0;
  //     }

  //     if (inputs.reteICA_new === "1" && row.reteICA > 0) {
  //       totallizacion2.reteICA_string = row.reteICA;
  //     } else {
  //       totallizacion2.reteICA_string = 0;
  //     }
  //     //totallizacion2.reteICA_string    = row.reteICA
  //     totallizacion2.total_string = row.total;
  //   } else {
  //     totallizacion2.subTotal_string +=
  //       row.subTotal !== undefined ? parseFloat(row.subTotal) : 0;
  //     totallizacion2.iva_string +=
  //       row.iva !== undefined ? parseFloat(row.iva) : 0;

  //     totallizacion2.rete_string +=
  //       row.rete !== undefined ? parseFloat(row.rete) : 0;

  //     if (inputs.reteiva_new === "1") {
  //       totallizacion2.reteIVA_string +=
  //         row.reteIVA !== undefined ? parseFloat(row.reteIVA) : 0;
  //     }

  //     if (inputs.reteICA_new === "1") {
  //       totallizacion2.reteICA_string +=
  //         row.reteICA !== undefined ? parseFloat(row.reteICA) : 0;
  //     }

  //     totallizacion2.total_string +=
  //       row.total !== undefined ? parseFloat(row.total) : 0;
  //   }
  //   return totallizacion2;
  // });
  //console.log(totalizacion_.total_string);

  //   totallizacion2.cantidad = totalizacion_.cantidad;
  //   totallizacion2.peso = totalizacion_.peso;
  //   totallizacion2.cantidad_string = totalizacion_.cantidad_string;
  //   totallizacion2.peso_string = totalizacion_.peso_string;
  //   setTotalizacion(totallizacion2);
  // };

  // const handleCheckAll = (source) => {
  //   let total1_ = 0;
  //   let total2_ = 0;
  //   var checkboxes = document.querySelectorAll('input[type="checkbox"]');
  //   for (var i = 0; i < checkboxes.length; i++) {
  //     if (checkboxes[i].name) {
  //       checkboxes[i].checked = source.target.checked;
  //       if (source.target.checked) {
  //         const json_ = JSON.parse(checkboxes[i].value);
  //         total1_ += parseFloat(json_.peso);
  //         total2_ += parseFloat(json_.cantidad);
  //       }
  //     }
  //   }
  //   setTotal1(total1_);
  //   setTotal2(total2_);
  // };

  const handleChangeCantidad = (event) => {
    Inputs(event, inputs, setInputs);
    const formattedValue = formatDinero(event.target.value);
    SetinputCantProdcut(formattedValue);
    SetCantidadVender(event.target.value);
  };

  const handleTrash = (row) => {
    return context.setModalShow({
      show: true,
      size: "md",
      message: (
        <div className="text-center">
          <div className="mb-3">
            ¿Está seguro deseas eliminar el registro <b>{row.marca}</b>?
            <br />
            <b>{row.op_inventario_items_inventario_id}</b>
          </div>
          <div
            className="btn btn-primary mt-2 me-2"
            onClick={() => {
              context.setModalShow({ show: false, message: "", size: "sm" });
              submitTrash();
            }}
          >
            Si
          </div>
          <div
            className="btn btn-secondary mt-2 "
            onClick={() => {
              context.setModalShow({ show: false, message: "", size: "sm" });
            }}
          >
            No
          </div>
        </div>
      ),
    });
  };
  const submitTrash = () => {
    PostAsync(
      "Inventario",
      "deleteVenta",
      { id: params.id },
      { ...context }
    ).then((response) => {
      history.push("/Inventario/ListadoVentas");
      console.log(response.message["label"]);
      if (response.message["label"] == "Venta eliminada correctamente") {
        setTimeout(function () {
          window.location.href = "/Ventas/ListadoVentas";
        }, 1000);
        return context.setModalShow({
          show: true,
          size: "md",
          message: (
            <div className="text-center">
              <div className="mb-3">
                <p>
                  <strong>Eliminado correctamente!</strong>
                </p>
              </div>
            </div>
          ),
        });
      }
    });
  };

  return (
    <div className="p-3">
      {!reset ? (
        <>
          <Row className="mb-4">
            <Col xs="12" md="6" className="h4" style={{ marginTop: "45px" }}>
              Módulo de venta{" "}
              {inputs.funcionario && <>({inputs.funcionario})</>}
              {inputs.consecutive && <h5>Consecutivo: {inputs.consecutive}</h5>}
            </Col>
            <Col xs="12" md="6" className="h4 text-end">
              {data.length > 0 && data[0].estatus === "1" ? (
                <>
                  <a
                    target="_blank"
                    className="btn btn-secondary  ms-2"
                    href={
                      Config.ConfigApirest +
                      "PDF/Inventario/CompraPrint/" +
                      params.id +
                      "/" +
                      context.user.token
                    }
                  >
                    PDF
                  </a>
                </>
              ) : (
                false
              )}
              {params.id &&
              params.id !== "New" &&
              Object.entries(items).length > 0 &&
              items[0].estatus === "1" ? (
                <>
                  {/* {console.log(items)} */}
                  <a
                    target="_blank"
                    className="btn btn-secondary  ms-2"
                    href={
                      Config.ConfigApirest +
                      "PDF/Inventario/VentaPrint/" +
                      params.id +
                      "/" +
                      context.user.token
                    }
                  >
                    PDF
                  </a>
                  <a
                    target="_blank"
                    className="btn btn-success ms-2"
                    href={
                      Config.ConfigApirest +
                      "Excel/Inventario/WordOfficeVenta/id:" +
                      params.id +
                      "/toke:" +
                      context.user.token
                    }
                  >
                    WordOffice
                  </a>
                </>
              ) : (
                false
              )}

              {props.me.subLabel !== "Papelera" &&
              params.id &&
              params.id !== "New" &&
              Object.entries(items).length > 0 &&
              items[0].estatus === "0" ? (
                <>
                  <div className="btn btn-warning ms-2" onClick={handleSave}>
                    Guardar
                  </div>
                  <div className="btn btn-warning ms-2" onClick={handleClose}>
                    Cerrar venta
                  </div>
                </>
              ) : params.id &&
                params.id !== "New" &&
                props.me.subLabel !== "Papelera" &&
                Object.entries(items).length > 0 &&
                items[0].estatus === "1" ? (
                <>
                  <div className="btn btn-danger ms-2" onClick={handleTrash}>
                    Borrar
                  </div>
                  <div
                    className={
                      parseInt(context.user.tipo_usuario_id) == "2" ||
                      parseInt(context.user.tipo_usuario_id) == "6"
                        ? "btn btn-warning ms-2"
                        : "d-none"
                    }
                    onClick={handleReOpen}
                  >
                    Reabrir venta
                  </div>
                </>
              ) : (
                false
              )}
            </Col>
          </Row>
        </>
      ) : (
        <Spinner animation="grow" />
      )}
      <Tabs
        defaultActiveKey="new-edit"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="new-edit" title="Nueva / Editar">
          {
            <form onSubmit={onSubmit} id="myForm">
              <Row className="mb-4">
                <Col xs="12">
                  {
                  <Select
                  //value={}
                  defaultValue={inputs.cliente}
                  url={"Users/ListaUsuarios"}
                  title="Cliente"
                  selectDefault="Cliente"
                  name="cliente"
                  data={clientes}
                  className="form-control"
                  onChange={onChange}
                  required={true}
                />
                  }
                </Col>
                {inputs.cliente ? (
                  <>
                    {/* {(Object.entries(items).length > 0 &&
                      items[0].estatus === "0") ||
                    Object.entries(items).length === 0 ? ( */}
                      <>
                        <Col
                          xs="12"
                          md="8"
                          className="mt-3 autocomplete-z-index"
                        >{
                          <Select
                          //value={}
                          defaultValue={inputs.producto}
                          url={"Inventario/get_productosSelect"}
                          title="Producto"
                          selectDefault="Producto"
                          name="producto"
                          data={productoSelect}
                          className="form-control"
                          onChange={onChange}
                          required={true}
                        />
                          }
                          <NavLink
                            className="form-control-label"
                            to="/Inventario/ListadoProductos"
                          >
                            Producto
                          </NavLink>
                        </Col>
                      </>
                    {// ) : (
                    //   false
                    // )}
                    }
                    {/* {(Object.entries(items).length>0 && items[0].estatus==='0') || (Object.entries(items).length===0)?<>
                            <Col xs="12" md="4" className="mt-3">
                              <Input
                                  defaultValue={inputs.fecha_recibido}
                                  title="Fecha de compra"
                                  placeholder="Fecha de compra"
                                  type="date"
                                  name="fecha_recibido"
                                  className="form-control"
                                  onChange={onChange}
                                  required={true}
                              />
                            </Col>
                          </>:false} */}
                    {((Object.entries(items).length > 0 &&
                      items[0].estatus === "0") ||
                      Object.entries(items).length === 0) && (
                      <>
                        <Col xs="12" md="2" className="mt-3">
                          <Input
                            title="Fecha desde"
                            placeholder="Fecha desde"
                            type="date"
                            name="fecha_inicio"
                            className="form-control"
                            onChange={onChange}
                            required={true}
                          />
                        </Col>
                        <Col xs="12" md="2" className="mt-3">
                          <Input
                            title="Fecha hasta"
                            placeholder="Fecha hasta"
                            type="date"
                            name="fecha_fin"
                            className="form-control"
                            onChange={onChange}
                            required={true}
                          />
                        </Col>
                      </>
                    )}
                  </>
                ) : (
                  false
                )}
              </Row>
            </form>
          }
          {inputs.cliente &&
          params.id !== "New" &&
          productos &&
          productos.data ? (
            <>
              <Row>
                <NavLink
                  className="form-control-label"
                  to="/Inventario/ListadoProductos"
                >
                  Producto
                </NavLink>
                <Col xs="12" md="4">
                  <div className="card">
                    <div className="card-body">
                      <h6>{inputs.producto_string}</h6>
                      <div>
                        Disponible cantidad: <b>{productos.data["cantidad"]}</b>
                      </div>
                      <div>
                        Disponible Peso: <b>{new Intl.NumberFormat("de-DE").format(productos.data["peso"])}</b>
                      </div>
                      <div className="text-center">
                        <h4>Vender</h4>
                        <SelectorGenerico
                          modelo="Maestros"
                          metodo="get_tablas_maestras"
                          tabla="ma_comprar"
                          param={param}
                          name="comprar"
                          setParam={setParam}
                        />
                        {console.log(param.comprar)}
                      </div>
                    </div>
                  </div>
                </Col>
                {param.comprar !== undefined && param.comprar === 1 ? (
                  <Col xs="12" md="8">
                    <div className="card">
                      <div className="card-body text-center">
                        <div className="mb-3">
                          Agregar producto en su totalidad (
                          {productos.data["medida"] == "Unidad" ? (
                            <b>{productos.data["cantidad"]}</b>
                          ) : (
                            <b>{productos.data["peso"]}</b>
                          )}
                          )
                        </div>
                        <div className="card-body">
                          <Form.Control
                            as="textarea"
                            placeholder="Observación"
                            name="observacion"
                            onChange={onChange}
                            style={{ height: "100px", resize: "none" }}
                          />
                        </div>
                        {/*This probably doesn't do anything anymore, it just hid the button if there was nothing to sell*/productos.data["cantidad"]>0?(
                        <div
                          className="btn btn-primary"
                          onClick={handleSubmitTotal}
                        >
                          Vender todo
                        </div>
                        ):false}
                      </div>
                    </div>
                  </Col>
                ) : param.comprar === 2 &&
                  productos.data["medida"] === "Kgr" ? (
                  <Col xs="12" md="8">
                    <div className="card">
                      <div className="card-body text-center">
                        <div className="mb-3">
                          Agregar producto en su menudeado total {new Intl.NumberFormat("de-DE").format(total1)}
                        </div>
                        <div className="row mb-3">
                          <div className="col-2 text-center">
                            <b>Fecha compra</b>
                          </div>
                          <div className="col text-center">
                            <b>Marca</b>
                          </div>
                          <div className="col-2 text-end">
                            <b>Cant</b>
                          </div>
                          <div className="col-2 text-end">
                            <b>Kgrs</b>
                          </div>
                          <div className="col-1">
                            <input type="checkbox" onClick={handleCheckAll} />
                          </div>
                        </div>
                        {productos.productos.length > 0 ? (
                          <>
                            {productos.productos.map((row, key) => {
                              // console.log(row);
                              return (
                                <div key={key} className="row">
                                  <div className="col-2">
                                    {row.fecha_recibido}
                                  </div>
                                  <div className="col text-center">
                                    {row.marca}
                                  </div>
                                  <div className="col-2 text-end">
                                    {row.cantidad}
                                  </div>
                                  <div className="col-2 text-end">
                                    {row.peso}
                                  </div>
                                  <div className="col-1 text-center">
                                    <input
                                      value={JSON.stringify(row)}
                                      name="foo"
                                      type="checkbox"
                                      onClick={(e) =>
                                        handleAddMenudeado(e, row)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          false
                        )}
                        <div className="row bg-primary text-white">
                          <div className="col-2"></div>
                          <div className="col text-start">Total</div>
                          <div className="col-2 text-end">{total2}</div>
                          <div className="col-2 text-end">{new Intl.NumberFormat("de-DE").format(total1)}</div>
                          <div className="col-1 text-center"></div>
                        </div>
                        <div className="card-body">
                          <Form.Control
                            as="textarea"
                            placeholder="Observación"
                            name="observacion"
                            onChange={onChange}
                            style={{ height: "100px", resize: "none" }}
                          />
                        </div>
                        <div
                          className="btn btn-primary mt-3"
                          onClick={handleSubmitMenudeado}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  param.comprar === 2 &&
                  productos.data["medida"] == "Unidad" && (
                    <Col xs="12" md="8">
                      <div className="card">
                        <div className="card-body text-center">
                          <div className="mb-3">
                            Agregar producto por unidad{" "}
                            <b>
                              {inputCantProdcut == "NaN" ? 0 : inputCantProdcut}
                            </b>{" "}
                            disponible
                          </div>
                          <div>
                            <input
                              name="cantidad_productos"
                              className="form-control text-center"
                              type="number"
                              min="0"
                              max={productos.data["cantidad"]}
                              onChange={handleChangeCantidad}
                            />
                          </div>
                          <div className="card-body">
                            <Form.Control
                              as="textarea"
                              placeholder="Observación"
                              name="observacion"
                              onChange={onChange}
                              style={{ height: "100px", resize: "none" }}
                            />
                            {CantidadVender.length > 0 ? (
                              <div
                                className="btn btn-primary mt-3 col-6"
                                onClick={handleSubmit}
                              >
                                Vender
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </>
          ) : (
            false
          )}
          {venta.estatus == "1" && venta.observacion && (
            <Card style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title>Observación</Card.Title>
                <Card.Text>{venta.observacion}</Card.Text>
              </Card.Body>
            </Card>
          )}
          {Object.entries(items).length > 0 && items[0].estatus === "1" ? (
            <>
              <div className="border p-2 bg-primary text-white">
                <div className="row">
                  <div className="col">
                    Cliente:{" "}
                    <b>
                      {inputs.nombres}
                       {/* {inputs.apellidos} */}
                    </b>
                  </div>
                  <div className="col">
                    Celular: <b>{inputs.celular}</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    ReteIVA: <b>{inputs.reteiva_new === "0" ? "No" : "Si"}</b>
                  </div>
                  {/*It was backwards for some reason?*/}
                  <div className="col">
                    Email: <b>{inputs.email}</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    ReteICA: <b>{inputs.reteICA_new === "0" ? "No" : "Si"}</b>
                  </div>
                  <div className="col">
                    Dirección:{" "}
                    <b>
                      {inputs.direccion} {inputs.departamento}{" "}
                      {inputs.municipio}
                    </b>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    ReteFuente:{" "}
                    <b>{inputs.retefuente_new === "0" ? "No" : "Si"}</b>
                  </div>
                  <div className="col">
                    IVA: <b>{inputs.es_iva=== "0" ? "No" : "Si"//parseFloat(inputs.reteIVA) <= 0 ? "No" : "Si"
                    }</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    NIT: <b>{inputs.nro_documento}</b>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </>
          ) : (
            false
          )}
          {!reset ? (
            <>
              {Object.entries(items).length > 0 ? (
                <>
                  <div className="table-responsive">
                    {/* tabla de abajo */}
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Producto</th>
                          <th className="text-center">Marca</th>
                          <th className="text-center">Fecha compra</th>
                          <th className="text-center">Fecha venta</th>
                          <th className="text-center">Cantidad</th>
                          <th className="text-center">Peso</th>
                          <th className="text-center">Precio</th>
                          <th className="text-center">Subtotal</th>
                          <th className="text-center">IVA</th>
                          <th className="text-center">ReteIVA</th>
                          <th className="text-center">ReteICA</th>
                          <th className="text-center">ReteF</th>
                          <th className="text-center">Total</th>
                          <th className="text-center">Estado</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemsAgrupados.map((row, key) => {
                          // console.log("row",row);
                          let marca = row.marca.split("-");
                          return (
                            <tr
                              key={row.op_inventario_items_inventario_venta_id}
                            >
                              {/* {console.log(row.op_inventario_items_inventario_venta_id)} */}
                              <td>
                                {row.op_inventario_items_inventario_venta_id}
                              </td>
                              <td className="text-center">{row.producto}</td>
                              <td className="text-center">
                                {marca[0] === "" ? "N/A" : marca[0]}
                              </td>
                              <td className="text-center">
                                {row.fecha_compra}
                              </td>
                              <td className="text-center">{row.fecha}</td>
                              <td className="text-center col-1">
                                {row.estatus === "1" ? (
                                  <>
                                    {typeof row.cantidad === "string"
                                      ? row.cantidad
                                      : format(row.cantidad)}
                                  </>
                                ) : (
                                  <>
                                    {row.ma_unidad_medida_id === "2" ? (
                                      <input
                                        name="cantidad"
                                        type="number"
                                        autoComplete="off"
                                        value={row.cantidad}
                                        className="form-control precio"
                                        onChange={(e) =>
                                          calcular(e, row, key, "cantidad")
                                        }
                                      />
                                    ) : (
                                      <td className="text-start col-1">
                                        {row.cantidad}
                                      </td>
                                    )}
                                  </>
                                )}
                              </td>
                              {row.ma_unidad_medida_id === "1" ? (
                                <td className="text-center">{row.peso}</td>
                              ) : (
                                <td className="text-center">{"N/A"}</td>
                              )}
                              <td className="text-center col-1">
                                {row.estatus === "1" ? (
                                  <>
                                    {parseInt(row.peso) > 0
                                      ? format(
                                          parseFloat(row.subTotal) /
                                            parseFloat(row.peso)
                                        )
                                      : parseInt(row.peso) === 0
                                      ? format(
                                          parseFloat(row.subTotal) /
                                            parseFloat(row.cantidad)
                                        )
                                      : "0.00"}
                                  </>
                                ) : (
                                  <>
                                    <input
                                      name="precio_individual"
                                      type="number"
                                      autoComplete="off"
                                      value={row.precio_individual}
                                      className="form-control precio"
                                      onChange={(e) =>
                                        calcular(e, row, key, "precio")
                                      }
                                    />
                                  </>
                                )}
                              </td>
                              <td className="text-center">
                                {!row.subTotal ? "0.00" : format(row.subTotal)}
                              </td>
                              <td className="text-center">
                                {row.iva<1 ? format(row.iva*row.subTotal) : format(row.iva)//I'll admit I have no clue why this doesn't show the correct data and I do not know, nor care why. It works
                                }
                              </td>
                              <td className="text-center">
                                {!row.reteIVA_string
                                  ? "0.00"
                                  : format(row.reteIVA)}
                              </td>
                              <td className="text-center">
                                {!row.reteICA_string ? "0.00" : format(row.reteICA)}
                              </td>
                              <td className="text-center">
                                {!row.rete ? "0.00" : format(row.rete)}
                              </td>
                              <td className="text-center">
                                {!row.total ? "0.00" : format(row.total)}
                              </td>
                              <td className="text-center">
                                <div className="text-center">
                                  {row.estatus_venta == "1" ? (
                                    <span
                                      style={{ color: "green" }}
                                      className="iconify mr-1"
                                      data-icon="mdi-tag-check"
                                    ></span>
                                  ) : (
                                    <span
                                      style={{ color: "red" }}
                                      className="iconify mr-1"
                                      data-icon="mdi-tag-remove"
                                    ></span>
                                  )}
                                </div>
                              </td>
                              <td className="">
                                <div className="d-flex justify-content-center">
                                  <div>
                                      <NavLink
                                      to={
                                        "/Inventario/Ventas/Edit/" +
                                        params.id +
                                        "/Detalle/" +
                                        (row.agrupados_ids.length <= 1 ? row.op_inventario_items_inventario_venta_id : row.agrupados_ids.join(",")) +
                                        "/" +
                                        row.consecutive +
                                        "/" +
                                        1
                                      }
                                      className="cursor-pointer"
                                    >
                                      <span
                                        style={{ color: "blue" }}
                                        className="iconify mr-1"
                                        data-icon="mdi-eye"
                                      ></span>
                                    </NavLink>
                                  </div>
                                  {/* <div>
                                {props.me.subLabel !== 'Papelera' &&
                                  params.id &&
                                  params.id !== 'New' &&
                                  (Object.entries(items).length > 0 &&
                                    items[0].estatus === '0') ? <>
                                  <div
                                    style={{ color: "red" }}
                                    className="cursor-pointer col"
                                    onClick={() => { deleteProductVenta(row.op_inventario_items_inventario_venta_id) }}>
                                    <Icon path={mdiDelete} size={1} />
                                  </div>
                                </> : params.id && params.id !== 'New' && props.me.subLabel !== 'Papelera' && (Object.entries(items).length > 0 && items[0].estatus === '1') ?
                                  <>
                                    <div className="">

                                    </div>
                                  </>
                                  : false}
                              </div> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          {totalizacion.cantidad_string === "2,00" ? (
                            <td className="text-center bg-secondary text-white"></td>
                          ) : (
                            <td className="text-start bg-secondary text-white">
                              {totalizacion.cantidad_string}
                            </td>
                          )}

                          <td className="text-center bg-secondary text-white">
                            {totalizacion.peso_string}
                          </td>
                          <td className="text-center"></td>
                          <td className="text-center bg-secondary text-white">
                            {!totalizacion.subTotal_string ? (
                              "0,00"
                            ) : typeof totalizacion.subTotal_string !==
                              "string" ? (
                              <>{format(totalizacion.subTotal_string)}</>
                            ) : (
                              totalizacion.subTotal_string
                            )}
                          </td>
                          <td className="text-center bg-secondary text-white">
                            {!totalizacion.iva_string ? (
                              "0,00"
                            ) : typeof totalizacion.iva_string !== "string" ? (
                              <>{format(totalizacion.iva_string)}</>
                            ) : (
                              totalizacion.iva_string
                            )}
                          </td>
                          <td className="text-center bg-secondary text-white">
                            {!totalizacion.reteIVA_string ? (
                              "0,00"
                            ) : typeof totalizacion.reteIVA_string !==
                              "string" ? (
                              <>{format(totalizacion.reteIVA_string)}</>
                            ) : (
                              totalizacion.reteIVA_string
                            )}
                          </td>
                          <td className="text-center bg-secondary text-white">
                            {!totalizacion.reteICA_string ? (
                              "0,00"
                            ) : typeof totalizacion.reteICA_string !==
                              "string" ? (
                              <>{format(totalizacion.reteICA_string)}</>
                            ) : (
                              totalizacion.reteICA_string
                            )}
                          </td>
                          <td className="text-center bg-secondary text-white">
                            {!totalizacion.rete_string ? (
                              "0,00"
                            ) : typeof totalizacion.rete_string !== "string" ? (
                              <>{format(totalizacion.rete_string)}</>
                            ) : (
                              totalizacion.rete_string
                            )}
                          </td>

                          <td className="text-center bg-primary text-white">
                            {!totalizacion.total_string ? (
                              "0,00"
                            ) : typeof totalizacion.total_string !==
                              "string" ? (
                              <>{format(totalizacion.total_string)}</>
                            ) : (
                              totalizacion.total_string
                            )}
                          </td>
                          {/* <td className="text-end bg-primary text-white">
                                {!totalizacion.total_string?"0,00":totalizacion.total_string?<>
                                  {(!isNaN(format(totalizacion.total)))?format(totalizacion.total):totalizacion.total_string} 
                                </>:false}
                              </td> */}
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </>
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}
        </Tab>
        <Tab
          eventKey="historical"
          title="Historial de Cambios"
          disabled={historial.length <= 0}
        >
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Fecha</th>
                <th className="text-center">Hora</th>
                <th className="text-center">Acción</th>
                <th className="text-center">Usuario</th>
              </tr>
            </thead>
            <tbody>
              {historial.map((row, key) => {
                return (
                  <tr key={key}>
                    <td className="text-center">{row.fecha_accion}</td>
                    <td className="text-center">{row.hora_accion}</td>
                    <td className="text-center">{row.accion}</td>
                    <td className="text-center">{row.usuario}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </div>
  );
};

export default App;
