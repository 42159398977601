import React,{useState,useEffect} from 'react';
import StateContext from '../helpers/ContextState';
import {PostAsync} from '../helpers/Functions';
import { NavLink, useHistory} from "react-router-dom";

const limit = 2000000000
const Resumen=({data})=>{
  return  <div className="row">
            <div className="col-6">
                <div className="bg-primary text-white">Validos:</div>
                {data.procesados.map((row,key)=>{
                  return  <div key={key} className="text-start ">
                            {row.proveedor} Ln: {row.linea}
                          </div>
                })}
            </div>
            <div className="col-6">
                <div className="bg-primary text-white">Invalidos:</div>
                {data.no_procesados.map((row, key) => {
                  const errorFields = Object.keys(row).filter(field => row[field] === null).join(' - ');
                  //{errorFields && `Error en Ln:`} <b>{row.linea}</b> {errorFields} 
                  return (
                    <div key={key} className="text-start">
                      {errorFields && `Error en: ${errorFields}`} <b>Ln: {row.linea}</b>
                    </div>
                  );
                })}
            </div>
          </div>
}
const App=()=>{
  let history                     =   useHistory();
  const context                           =   React.useContext(StateContext);
  const handleCapture = (e) => {

    if (e.files) {
      if (e.files.length !== 0) {

        if (e.files[0].size>=limit) {
          context.setModalShow({
                          show:true,
                          message:  <div>
                                        Imagen supera límite permitido de ({limit/1000000}) MB
                                        <div className="btn btn-primary"  onClick={()=>context.setModalShow(false)}>Reintentar con otra</div>
                                    </div>,
                          size:""
                        }
                      )
        }
        context.setModalShow({
                        show:true,
                        message:"Cargando archivo al sistema",
                        size:""
                      }
                    )
        PostAsync("Inventario","UploadExcel",{file:e.files[0]},context).then((response)=>{
          if (response.procesados && response.no_procesados && !response.data) {
            context.setModalShow({
                            show:true,
                            message: <Resumen data={response}/> ,
                            size:"lg"
                          }
                        )

          } else if (response.data) {
            history.push("/Inventario/Compras/Edit/"+response.data);
            context.setModalShow(false)
          }
        })
      }
    }
  };
  return  <div className="col-12 col-sm-2 mb-3 text-center position-relative">
            <input type="file" className="inputfile" onChange={(e) => handleCapture(e.target)}/>
            <div className="btn btn-primary">
              Subir Plantilla
            </div>
          </div>
}
export default App
