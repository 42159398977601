import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';
import Config from '../../helpers/Config';


import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const context                           =   React.useContext(StateContext);
  const [param, setParam]                 =   useState({tipo_usuario_id:2,tipo_transaccion:"venta"});

  let tds       =   [
    // {
    //   label:"Fecha",
    //   value:"fecha_venta_srting",
    //   className:"col-sm-1 text-center",
    //   classNameHeader:"text-center",
    // },
    // {
    //   label:"Día",
    //   value:"dia",
    //   className:"col-sm-1 text-center",
    //   classNameHeader:"text-center",
    // },
    {
      label:"Producto",
      value:"producto",
      className:" text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Cantidad restante",
      value:"cantidadRestante",
      className:" text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Peso restante (Kg)",
      value:"pesoRestante",
      className:" text-left",
      classNameHeader:"col text-left",
    },
    {
      // total:true,
      label:"Cantidad comprada",
      value:"totalCantidadCompra",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      // total:true,
      label:"Cantidad vendida",
      value:"totalCantidadVenta",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      // total:true,
      label:"Peso comprado",
      value:"totalPesoCompra",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      // total:true,
      label:"Peso vendido",
      value:"totalPesoVenta",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Monto compra",
      value:"totalCompra_string",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Monto venta",
      value:"totalVenta_string",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      label:"Acciones",
      value:"events",
      className:"col-12 col-sm-1 text-center",
      classNameHeader:"col-1 text-center",
      icons:[
              {
                label:"Resumen",
                NavLink:NavLink,
                keys:true,
                icon: <FontAwesomeIcon icon={faEye}/>,
                url:"/Informes/InformesCompraVenta/Ver/"
              },
            ]
    },
  ]

  return  <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Table
                  search={false}
                  toExport={Config.ConfigApirest+'Excel/Informes/ListaCompraVenta/token:'+context.user.token}
                  toPDF={Config.ConfigApirest+'PDF/Informes/ListaCompraVenta/'+context.user.token}
                  customFiltersI0={Config.ConfigApirest+'Excel/Informes/ListadoCompraVenta/token:'+context.user.token}
                  total={true}
                  td={tds}
                  modelo="Informes"
                  metodo="ListaCompraVenta"
                  limit="10"
                  params={param}
                />
              </div>
            </div>
          </div>

          
}
export default App
