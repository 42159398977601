import React, { useState, useEffect } from 'react';
import InputSimple from '../../components/InputSimple';
import { PostAsync, Segment, Inputs, format } from "../../helpers/Functions";
import StateContext from '../../helpers/ContextState';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Comentarios from '../comentarios';
import BotonVolverHome from '../../components/BotonVolverHome';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from "react-bootstrap/Table";
import "../../components/HistorialTable.css";
import { setIn } from 'immutable';


const App = () => {
  const [historial, setHistorial] = useState([]);
  const [historialVentas, setHistorialVentas] = useState([]);
  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({});
  const [inputsVentas, setInputsVentas] = useState({});
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (reset) {
      setReset(false)
    }
  }, [reset])

  useEffect(() => {
    let mounted = true
    getInit(mounted)
    getInit2(mounted)
    return function cleanup() {
      mounted = false
    }
  }, [])
  let datArray=[];
  const getInit = (mounted, uid) => {
    PostAsync("Configuracion", "GetSystem", {}, { ...context }).then((response) => {
      if (response.data !== undefined && mounted) {
        datArray=response.data
        datArray.base_reteica=format(response.data.base_reteica*1)//Formats. It sucks, sorry
        datArray.base=format(response.data.base*1)
        datArray.base_retefuente=format(response.data.base_retefuente*1)
        console.log("Look at this ",response.data, "Copy ",datArray)
        setInputs(datArray);
      } 
    })
    PostAsync("Configuracion", "GetHistorialSystem", {}, { ...context }).then((response) => {
      if (response.historial !== undefined && mounted) {
        setHistorial(response.historial);
        console.log("Historial:", response.historial);
      }
    })
  }
  //VENTAS
  const getInit2 = (mounted, uid) => {
    PostAsync("Configuracion", "GetSystemVentas", {}, { ...context }).then((response) => {
      if (response.data !== undefined && mounted) {
        datArray=response.data
        datArray.base_reteica=format(response.data.base_reteica*1)//Formats. It sucks, sorry
        datArray.base=format(response.data.base*1)
        datArray.base_retefuente=format(response.data.base_retefuente*1)
        console.log("Look at this ",response.data, "Copy ",datArray)
        setInputsVentas(response.data)//Do not know, do not ask
      }
    })
    PostAsync("Configuracion", "GetHistorialSystemVentas", {}, { ...context }).then((response) => {
      if (response.historial !== undefined && mounted) {
        setHistorialVentas(response.historial);
        console.log("HistorialVentas:", response.historial);
      }
    })
  }

  const onChange = (e) => {
    Inputs(e, inputs, setInputs)
  }
  const onChange2 = (e) => {
    Inputs(e, inputsVentas, setInputsVentas)
  }
  const onSubmit = (e) => {
    e.preventDefault()
    if (inputs.base.includes(',')){
      inputs.base=inputs.base.substring(0, inputs.base.indexOf(','));
    } if (inputs.base_retefuente.includes(',')){
    inputs.base_retefuente=inputs.base_retefuente.substring(0, inputs.base_retefuente.indexOf(','));
    } if (inputs.base_reteica.includes(',')){
    inputs.base_reteica=inputs.base_reteica.substring(0, inputs.base_reteica.indexOf(','));//Deformat, it is kind of stupid
    }
    inputs.base=inputs.base.replaceAll(".", "")
    inputs.base_retefuente=inputs.base_retefuente.replaceAll(".", "")
    inputs.base_reteica=inputs.base_reteica.replaceAll(".", "")
    PostAsync("Configuracion", "SetSystem", { ...inputs, tipo: "compras" }, { ...context }).then((response) => {
      setReset(true)
      getInit(true)
    })
  }
  const onSubmit_venta = (e) => {
    e.preventDefault()
    if (inputsVentas.base.includes(',')){
      inputsVentas.base=inputsVentas.base.substring(0, inputsVentas.base.indexOf(','));
    } if (inputsVentas.base_retefuente.includes(',')){
    inputsVentas.base_retefuente=inputsVentas.base_retefuente.substring(0, inputsVentas.base_retefuente.indexOf(','));
    } if (inputsVentas.base_reteica.includes(',')){
    inputsVentas.base_reteica=inputsVentas.base_reteica.substring(0, inputsVentas.base_reteica.indexOf(','));//Deformat
    }
    inputsVentas.base=inputsVentas.base.replaceAll(".", "")
    inputsVentas.base_retefuente=inputsVentas.base_retefuente.replaceAll(".", "")
    inputsVentas.base_reteica=inputsVentas.base_reteica.replaceAll(".", "")
    PostAsync("Configuracion", "SetSystem_venta", { ...inputsVentas, tipo: "ventas"}, { ...context }).then((response) => {
      setReset(true)
      getInit2(true)
    })
  }
  return (
    <>
      <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="profile" title="Compras">
          <Row className="mb-4">
            <form onSubmit={onSubmit}>
              <Row className="mb-4">
                {/* Aquí van los campos del formulario */}
                <Col xs="12" md="2">
                  <InputSimple className="form-control"
                    type="number"
                    step={0.01}
                    name="iva"
                    defaultValue={inputs.iva}
                    placeholder="IVA"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple className="form-control"
                    type="number"
                    name="reteiva"
                    defaultValue={inputs.reteiva}
                    step={0.01}
                    placeholder="Rete IVA"
                    onChange={onChange}
                  />
                  <br />
                  <InputSimple className="form-control"
                    type="text"
                    name="base"
                    defaultValue={inputs.base}
                    placeholder="Base Rete IVA"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple className="form-control"
                    type="number"
                    name="reteica"
                    defaultValue={inputs.reteica}
                    step={0.01}
                    placeholder="Rete ICA"
                    onChange={onChange}
                  />
                  <br />
                  <InputSimple className="form-control"
                    type="text"
                    name="base_reteica"
                    defaultValue={inputs.base_reteica}
                    placeholder="Base Rete ICA"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple className="form-control"
                    type="number"
                    name="retefuente"
                    defaultValue={inputs.retefuente}
                    step={0.01}
                    placeholder="Rete Fuente"
                    onChange={onChange}
                  />
                  <br />
                  <InputSimple className="form-control"
                    type="text"
                    name="base_retefuente"
                    defaultValue={inputs.base_retefuente}
                    placeholder="Base Rete Fuente"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="2">
                  <BotonVolverHome />
                </Col>
                <Col xs="12" md="2">
                  <Button type="submit">Actualizar</Button>
                </Col>
              </Row>
            </form>
          </Row>
  
              <div className="row">
                <div className="col h5 mt-3">Historial cambios de Configuracion Tributaria</div>
              </div>
              <div className="row">
                <div className="col">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Fecha de cambio</th>
                      <th className="group-iva">IVA Anterior</th>
                      <th className="group-iva act">IVA Actual</th>
                      <th className="group-rete-iva">Rete IVA Anterior</th>
                      <th className="group-rete-iva act">Rete IVA Actual</th>
                      <th className="group-rete-ica">Rete ICA Anterior</th>
                      <th className="group-rete-ica act">Rete ICA Actual</th>
                      <th className="group-rete-fuente">Rete Fuente Anterior</th>
                      <th className="group-rete-fuente act">Rete Fuente Actual</th>
                      <th className="group-base-iva">Base Rete IVA Anterior</th>
                      <th className="group-base-iva act">Base Rete IVA Actual</th>
                      <th className="group-base-ica">Base Rete ICA Anterior</th>
                      <th className="group-base-ica act">Base Rete ICA Actual</th>
                      <th className="group-base-fuente">Base Rete Fuente Anterior</th>
                      <th className="group-base-fuente act">Base Rete Fuente Actual</th>
                      <th className="text-center">Observación</th>
                      <th className="text-center">Fecha Aplicación</th>
                      <th className="text-center">Funcionario</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historial && historial.length > 0 ? (
                      historial.map((row, key) => (
                        <tr key={key}>
                          <td className="text-center">{key + 1}</td>
                          <td className="text-center act">{row.fecha_cambio || 'N/A'}</td>
                          <td className="group-iva">{row.iva_anterior || 'N/A'}</td>
                          <td className="group-iva act">{row.iva_actual || 'N/A'}</td>
                          <td className="group-rete-iva">{row.reteiva_anterior || 'N/A'}</td>
                          <td className="group-rete-iva act">{row.reteiva_actual || 'N/A'}</td>
                          <td className="group-rete-ica">{row.reteica_anterior || 'N/A'}</td>
                          <td className="group-rete-ica act">{row.reteica_actual || 'N/A'}</td>
                          <td className="group-rete-fuente">{row.retefuente_anterior || 'N/A'}</td>
                          <td className="group-rete-fuente act">{row.retefuente_actual || 'N/A'}</td>
                          <td className="group-base-iva">{format(row.base_anterior*1) || 'N/A'}</td>
                          <td className="group-base-iva act">{format(row.base_actual*1) || 'N/A'}</td>
                          <td className="group-base-ica">{format(row.base_reteica_anterior*1) || 'N/A'}</td>
                          <td className="group-base-ica act">{format(row.base_reteica_actual*1) || 'N/A'}</td>
                          <td className="group-base-fuente">{format(row.base_retefuente_anterior*1) || 'N/A'}</td>
                          <td className="group-base-fuente act">{format(row.base_retefuente_actual*1) || 'N/A'}</td>
                          <td className="text-center">{row.observacion || 'Sin Observación'}</td>
                          <td className="text-center">{row.fecha_aplicacion || 'N/A'}</td>
                          <td className="text-center">{row.funcionario_name || 'Desconocido'}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="19" className="text-center">
                          No hay registros en el historial.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                </div>
              </div>
        </Tab>
  
        <Tab eventKey="profile2" title="Ventas">
          <Row className="mb-4">
            <form onSubmit={onSubmit_venta}>
              <Row className="mb-4">
                {/* Aquí van los campos del formulario de ventas */}
                <Col xs="12" md="2">
                  <InputSimple className="form-control"
                    type="number"
                    step={0.01}
                    name="iva"
                    defaultValue={inputsVentas.iva}
                    placeholder="IVA"
                    onChange={onChange2}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple className="form-control"
                    type="number"
                    name="reteiva"
                    defaultValue={inputsVentas.reteiva}
                    step={0.01}
                    placeholder="Rete IVA"
                    onChange={onChange2}
                  />
                  <br />
                  <InputSimple className="form-control"
                    type="text"
                    name="base"
                    defaultValue={inputsVentas.base}
                    placeholder="Base Rete IVA"
                    onChange={onChange2}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple className="form-control"
                    type="number"
                    step={0.01}
                    name="reteica"
                    defaultValue={inputsVentas.reteica}
                    placeholder="Rete ICA"
                    onChange={onChange2}
                  />
                  <br />
                  <InputSimple className="form-control"
                    type="text"
                    name="base_reteica"
                    defaultValue={inputsVentas.base_reteica}
                    placeholder="Base Rete ICA"
                    onChange={onChange2}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple className="form-control"
                    type="number"
                    step={0.01}
                    name="retefuente"
                    defaultValue={inputsVentas.retefuente}
                    placeholder="Rete Fuente"
                    onChange={onChange2}
                  />
                  <br />
                  <InputSimple className="form-control"
                    type="text"
                    name="base_retefuente"
                    defaultValue={inputsVentas.base_retefuente}
                    placeholder="Base Rete Fuente"
                    onChange={onChange2}
                  />
                </Col>
                <Col xs="12" md="2">
                  <BotonVolverHome />
                </Col>
                <Col xs="12" md="2">
                  <Button type="submit">Actualizar</Button>
                </Col>
              </Row>
            </form>
          </Row>
  
              <div className="row">
                <div className="col h5 mt-3">Historial cambios de Configuracion Tributaria Ventas</div>
              </div>
              <div className="row">
                <div className="col">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      {/* <th className="text-center">Fecha de cambio</th> */}
                      <th className="group-iva act">IVA</th>
                      <th className="group-rete-iva act">Rete IVA</th>
                      <th className="group-rete-ica act">Rete ICA</th>
                      <th className="group-rete-fuente act">Rete Fuente</th>
                      <th className="group-base-iva act">Base Rete IVA</th>
                      <th className="group-base-ica act">Base Rete ICA</th>
                      <th className="group-base-fuente act">Base Rete Fuente</th>
                      {/* <th className="text-center">Observación</th>
                      <th className="text-center">Fecha Aplicación</th>
                      <th className="text-center">Funcionario</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {historialVentas && historialVentas.length > 0 ? (
                      historialVentas.map((row, key) => (
                        <tr key={key}>
                          <td className="text-center">{key + 1}</td>
                          {/* <td className="text-center act">{row.fecha_cambio || 'N/A'}</td> */}
                          <td className="group-iva act">{row.iva || 'N/A'}</td>
                          <td className="group-rete-iva act">{row.reteiva || 'N/A'}</td>
                          <td className="group-rete-ica act">{row.reteica || 'N/A'}</td>
                          <td className="group-rete-fuente act">{row.retefuente || 'N/A'}</td>
                          <td className="group-base-iva act">{format(row.base*1) || 'N/A'}</td>
                          <td className="group-base-ica act">{format(row.base_reteica*1) || 'N/A'}</td>
                          <td className="group-base-fuente act">{format(row.base_retefuente*1) || 'N/A'}</td>
                          {/* <td className="text-center">{row.observacion || 'Sin Observación'}</td>
                          <td className="text-center">{row.fecha_aplicacion || 'N/A'}</td>
                          <td className="text-center">{row.funcionario_name || 'Desconocido'}</td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="19" className="text-center">
                          No hay registros en el historial.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                </div>
              </div>
          <Row>
            <Col xs="12">
              {!reset ? <Comentarios id={"Ventas"} /> : false}
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </>
  );
  

}
export default App

