const exportar  = {
 Prefijo:"/pwa",
 ConfigAppUrl : window.location.origin+"/",
 ConfigSocketUrl : (document.location.hostname==='localhost')?'https://socket.pagoslocales.com:3020/':(process.env.NODE_ENV==='development')?'https://socket.pagoslocales.com:3020/':'https://socket.pagoslocales.com:3020/',
 ConfigNotifications:process.env.REACT_APP_URL_NOTIFICATIONS,
 //ConfigApirest   : (document.location.hostname==='localhost')?'http://localhost/apirest_inventory/':(process.env.NODE_ENV==='development')?'https://apirest.fenixteayuda.com/':'https://apirest.fenixteayuda.com/',
 //ConfigApirest   : (document.location.hostname==='localhost' && 1===1)?'http://localhost/apirest_inventory/':(process.env.NODE_ENV==='development')?'https://asocolcarnesbackend.programandoweb.net/':'https://asocolcarnesbackend.programandoweb.net/',
 ConfigApirest   : (document.location.hostname==='localhost' && 1==3)?'http://localhost:8081/asocolcarnes-backend-ap/':(process.env.NODE_ENV==='development')?'https://apirest.fenixteayuda.com/':'https://apirest.fenixteayuda.com/',
 Title:'Inventory',
 Alt:'Inventory',
}

//const exportar  = {
//  Prefijo: "/pwa",
//  ConfigAppUrl: window.location.origin + "/",
//  ConfigSocketUrl: "http://localhost:80/",
//  ConfigNotifications: process.env.REACT_APP_URL_NOTIFICATIONS,
//  ConfigApirest: "https://localhost/asocolcarnes-backend-api/",
//  Title: "Inventory",
//  Alt: "Inventory",
//}

export default exportar;